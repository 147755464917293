import { StyleSheet } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

const createStyles = (coresPWA) =>

  StyleSheet.create({

    imageContainer: {
      width: wp('40%'), // Ajuste o tamanho conforme necessário
      height: wp('40%'), // Ajuste o tamanho conforme necessário
      borderRadius: wp('60%'), // Isso tornará o contêiner redondo
      overflow: 'hidden', // Certifica-se de que a imagem dentro do contêiner seja cortada para se ajustar ao formato do contêiner
      alignItems: 'center', // Alinhe a imagem ao centro horizontalmente
      justifyContent: 'center', // Alinhe a imagem ao centro verticalmente
      borderColor: 'green',
      borderWidth: 1,
    },
    image: {
      width: '100%', // A imagem ocupa 100% do contêiner
      height: '100%', // A imagem ocupa 100% do contêiner
      resizeMode: 'cover', // A imagem é redimensionada para cobrir todo o contêiner
      marginTop: 5,
    },

    button: {
      backgroundColor: '#1976d2',
      minWidth: '100%',  // certifique-se de que wp seja importado
      marginTop: 10,
    },
    buttonText: {
      color: '#fff',
      fontSize: RFPercentage(2.5) // certifique-se de que RFPercentage seja importado
    },
    headerText: {
      fontSize: RFPercentage(3.5),
      marginBottom: 10,
      fontWeight: "bold",
      color: coresPWA.corFontePrimaria || '#000',
    },
    textFoneInternacional: {
      marginBottom: 25,
      fontWeight: "bold",
      color: coresPWA.corFontePrimaria || '#000',
    },
    container: {
      height: hp('100%'),
      // justifyContent: 'center',
      marginTop: 30,
      alignItems: 'center',
      // backgroundColor: '#fff',
    },
    footer: {
      height: hp('24%'),
      justifyContent: 'center',
      alignItems: 'center',
      // backgroundColor:'blue'
    },
    textCadastro: {
      marginTop: 15,
      color: coresPWA.corFontePrimaria || '#000',
    },

    textPadrao: {
      color: coresPWA.corFontePrimaria || '#000',
    },

    corFonteSubtitulos: {
      color: coresPWA.corFonteSubtitulo || '#0000FF',
    },

  });

export default createStyles;