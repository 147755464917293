import { StyleSheet } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


export default StyleSheet.create({
    containerFlex: {
        flexDirection: 'row',
        // direction: 'row',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 2
    },
    leftFlex: { 
        flexDirection: 'row', 
        width: '78%',
        height: '100%',
        justifyContent: 'flex-start', 
        alignItems: 'center',
    },
    rightFlex: {
        direction: 'row',
        width: '22%',
        height: '100%',
        justifyContent: 'center',  
    },
    itemText: {
        // color: '#000', 
    },
    itemTextWithMargin: {
        // color: '#000', 
        marginLeft: 10
    },
    itemTextWithMarginBrinde: {
        // color: '#000', 
        marginLeft: 10,
        fontWeight: 'bold'
    },
    imageItem: {
        height: 90,
        width: 90,
        borderRadius: 5,
    },
    mainContainer: {
        alignItems: 'center',
        marginTop: 3,
        width: '100%',
        justifyContent: 'center', 
    },
    pressableItem: {
        width: '100%',
        height: 100,
        borderColor: 'rgba(0, 0, 0, 0.2)',
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        marginTop: 10,
    }

});