import { StyleSheet } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


export default StyleSheet.create({

  skeletonContainerFlex: {
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 2
  },

  itemSkeleton: {
    width: "12%",
    height: 40,
    borderRadius: 7, 
  },
  flexitensSketon: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5
  },
  hStack: {
    alignItems: "center",
    marginBottom: 10,
    marginTop: 7,
  }


});