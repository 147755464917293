import React, { useEffect, useRef, useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import {
  Box,
  Flex,
  Image,
  Text,
  useColorMode,
  useColorModeValue,
} from "native-base";
import { RFPercentage } from "react-native-responsive-fontsize";
import Input from "../../../../components/Input";
import { useNavigation, useRoute } from "@react-navigation/native";
import Api from "../../../../Api/Api";
import Template from "../../../template/Index";
import styles from "./styles";
import { useTheme } from "../../../../ThemeProvider";
import SkeletonItens from "../../../components/Skeleton/Itens";
import StorageUtil from "../../../../Utils";
import ModalConfirmacao from "../../../../components/ModalConfirmacao";

export default function Servicos({}) {
  const {
    cor_rgba,
    isMobile,
    brindeAniversarianteMes,
    mesAtual,
    getCodEmpresa,
    configEmpresa,
  } = useTheme();

  const navigation = useNavigation();
  type RouteParams = {
    params: any;
    servicos_adicionais?: boolean;
  };

  type itensPrametors = {
    item: any;
    index: number;
  };

  const route = useRoute<RouteParams>();
  const refModal = useRef<any>(null);
  const confirmationRef = useRef<any>(null);
  const confirmationRemoverBrindeRef = useRef<any>(null);

  const [servicos_selecionados, setServicosSelecionados] = useState<any[]>([]);
  const [filtro, setFiltro] = useState<String>("");
  const [servicos, setServicos] = useState<any[]>([{}, {}, {}]);
  const [servicos_original, setServicosOriginal] = useState<any[]>([]);
  const [buscando, setBuscando] = useState<Boolean>(true);
  const [servicoBrindeSelecionar, setServicoBrindeSelecionar] = useState<any[]>(
    []
  );
  const [exibeBrinde, setExibeBrinde] = useState<boolean>(false);
  const [brindeRemover, setBrindeRemover] = useState<any>({});

  const [getConfigEmpresa, setConfigEmpresa] = useState<any>(
    JSON.parse(configEmpresa)
  );

  const { colorMode } = useColorMode();

  useEffect(() => {
    if (brindeAniversarianteMes && !getConfigEmpresa.utilizaPwaSimples) {
      validaServicoBrinde();
    }

    getServicos();
  }, []);

  async function validaServicoBrinde() {
    let peloMenosUmBrinde =
      route.params?.profissional_selecionado?.servicos.find(
        (item: any) => item.brindeAniversario
      );

    if (!peloMenosUmBrinde) {
      return;
    }

    const value: { id: number; dtNascimento: string } | null =
      await StorageUtil.getItem("cliente");

    if (value && value.id > 0) {
      const extraiMes = new Date(value.dtNascimento).getMonth() + 1;

      if (mesAtual == extraiMes) {
        const servicoBrinde = await Api.buscaUtilizacaoServicoBrinde(
          value.id,
          getCodEmpresa
        );

        if (servicoBrinde.status && servicoBrinde.data.length > 0) {
          setServicoBrindeSelecionar(servicoBrinde.data);
          confirmationRef.current.abrirModal();
        }
      }
    }
  }

  //OBS: aqui tem 2 regras para busca de serviço, 1 pega do serviços relacionados do cliente 2 pega direto da tab_serviços
  async function getServicos() {
    setBuscando(true);

    let serv = [];

    if (getConfigEmpresa.utilizaPwaSimples || getConfigEmpresa.versaoPWA == 2) {
      const ret = await Api.servicos(getCodEmpresa);
      serv = ret.data;
    } else {
      serv = route.params?.profissional_selecionado?.servicos;
    }

    serv.map((item: any) => {
      item.selecionado = false;
    });

    const ordenado = serv.sort((a, b) => {
      if (a.brindeAniversario && !b.brindeAniversario) {
        return -1;
      }
      if (!a.brindeAniversario && b.brindeAniversario) {
        return 1;
      }
      return 0;
    });

    setServicos(ordenado);
    setServicosOriginal(ordenado);
    setServicosSelecionados([]);

    setBuscando(false);
  }

  function onPressItemSelecionado(item: any, ignoraBrinde: boolean = false) {
    const servico_copy = [...servicos_selecionados];
    const servicos_copy = [...servicos];

    if (item.brindeAniversario && !ignoraBrinde) {
      const index = servico_copy.findIndex(
        (item_servico) => item_servico.id === item.id
      );

      if (index > -1) {
        setBrindeRemover(item);
        confirmationRemoverBrindeRef.current.abrirModal();
        return;
      }
    }

    const index = servico_copy.findIndex(
      (item_servico) => item_servico.id === item.id
    );

    if (index === -1) {
      servico_copy.push(item);

      const index_servico = servicos_copy.findIndex(
        (item_servico) => item_servico.id === item.id
      );
      servicos_copy[index_servico].selecionado = true;
    } else {
      servico_copy.splice(index, 1);
      const index_servico = servicos_copy.findIndex(
        (item_servico) => item_servico.id === item.id
      );
      servicos_copy[index_servico].selecionado = false;
    }

    setServicosSelecionados(servico_copy);
    setServicos(servicos_copy);
  }

  function linhaServico(item: any) {
    return getConfigEmpresa.utilizaPwaSimples ||
      getConfigEmpresa.exibeTempoServicoPWA ? (
      <Flex style={[styles.containerFlex, {}]}>
        {isMobile && (
          <Image
            source={
              item.fotoThumbnail == null
                ? require("../../../img/corte-de-cabelo.png")
                : {
                    uri: item.fotoThumbnail?.url,
                  }
            }
            alt="Avatar"
            resizeMode="cover"
            style={styles.imageItem}
          />
        )}

        <Box
          style={{
            width: "75%",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Text
            style={[
              styles.itemTextWithMargin,
              {
                color: item.selecionado
                  ? "black"
                  : getConfigEmpresa.coresPWA.corFontePrimaria,
              },
            ]}
            fontSize="md"
          >
            {item.nome}
          </Text>

          {item.brindeAniversario && (
            <Text
              style={[
                styles.itemTextWithMarginBrinde,
                {
                  color: item.selecionado
                    ? "black"
                    : getConfigEmpresa.coresPWA.corFontePrimaria,
                },
              ]}
              fontSize="sm"
            >
              {item.descricao}
            </Text>
          )}

          <Text
            style={[
              styles.itemText,
              {
                marginLeft: 10,
                marginTop: 7,
                color: item.selecionado
                  ? "black"
                  : getConfigEmpresa.coresPWA.corFontePrimaria,
              },
            ]}
            fontSize="md"
          >{`R$ ${item.valor} - ${item.tempo} Min`}</Text>
        </Box>
      </Flex>
    ) : (
      <Flex style={styles.containerFlex}>
        <Box style={styles.leftFlex}>
          {isMobile && (
            <Image
              source={
                item.fotoThumbnail == null
                  ? require("../../../img/corte-de-cabelo.png")
                  : {
                      uri: item.fotoThumbnail?.url,
                    }
              }
              alt="Avatar"
              resizeMode="cover"
              style={styles.imageItem}
            />
          )}

          <Box
            style={{
              width: "75%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Text
              style={[
                styles.itemTextWithMargin,
                {
                  color: item.selecionado
                    ? "black"
                    : getConfigEmpresa.coresPWA.corFontePrimaria,
                },
              ]}
              fontSize="md"
            >
              {item.nome}
            </Text>
            {item.brindeAniversario && (
              <Text
                style={[
                  styles.itemTextWithMarginBrinde,
                  {
                    color: item.selecionado
                      ? "black"
                      : getConfigEmpresa.coresPWA.corFontePrimaria,
                  },
                ]}
                fontSize="sm"
              >
                {item.descricao}
              </Text>
            )}
          </Box>
        </Box>

        <Flex style={[styles.rightFlex, {}]}>
          <Text
            style={[
              styles.itemText,
              {
                color: item.selecionado
                  ? "black"
                  : getConfigEmpresa.coresPWA.corFontePrimaria,
              },
            ]}
            fontSize="md"
          >{`R$ ${item.valor}`}</Text>
        </Flex>
      </Flex>
    );
  }

  function renderFiltro() {
    return (
      <Input
        type={"text"}
        icon={"search"}
        value={String(filtro || "")}
        width={"100%"}
        colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
        corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
        onChangeText={(text) => {
          setFiltro(text);
          const servicos_copy = [...servicos_original];
          const servicos_filtrados = servicos_copy.filter((item) => {
            return item.nome.toUpperCase().includes(text.toUpperCase());
          });
          setServicos(servicos_filtrados);
        }}
        placeholder="Nome do serviço"
      />
    );
  }

  function renderItem({ item, index }: itensPrametors) {
    if (item.brindeAniversario && !exibeBrinde) {
      return null;
    }

    return (
      <TouchableOpacity
        key={index}
        style={[
          styles.pressableItem,
          {
            borderColor: getConfigEmpresa.coresPWA.corBordaItens,
            // { borderColor: item.selecionado ? 'red' : 'rgba(0, 0, 0, 0.2)',
            // { borderColor: item.selecionado ? cor_rgba ?? 'defaultColor' : 'rgba(0, 0, 0, 0.2)',
            backgroundColor: item.selecionado ? "#ECFFDC" : "" + colorMode,
          }, //mais clara
        ]}
        onPress={() => onPressItemSelecionado(item)}
      >
        {buscando ? <SkeletonItens /> : linhaServico(item)}
      </TouchableOpacity>
    );
  }

  const renderEmptyComponent = () => (
    <Text
      style={{
        textAlign: "center",
        marginTop: 20,
        fontSize: RFPercentage(2.5),
        color: getConfigEmpresa.coresPWA.corFontePrimaria,
      }}
    >
      Nenhum registro encontrado.
    </Text>
  );

  const validaTotalServico = (servicos_selecionados: any[]) => {
    let total = 0;
    servicos_selecionados.map((item) => {
      total += parseFloat(item.valor);
    });

    return total.toFixed(2);
  };

  function confirmarRemoverBrinde() {
    onPressItemSelecionado(brindeRemover, true);
    confirmationRemoverBrindeRef.current.fecharModal();
  }

  function confirmarUtilizacaoBrinde() {
    setExibeBrinde(true);

    for (const brinde of servicoBrindeSelecionar) {
      const encontraBrinde = servicos.find((item) => item.id === brinde.id);

      if (encontraBrinde) {
        onPressItemSelecionado(encontraBrinde);
      }
    }
    confirmationRef.current.fecharModal();
  }

  return (
    <Template
      titulo={
        route.params?.servicos_adicionais
          ? "Serviços Adicionais"
          : "Selecione os Serviços"
      }
      exibeLogo={
        !route.params?.servicos_adicionais &&
        (getConfigEmpresa?.utilizaPwaSimples ||
          getConfigEmpresa?.versaoPWA == 2)
      }
      exibeMenuSuperior={getConfigEmpresa?.exibeMenuSuperiorPWA}
      // urlLogo={'https://i.ibb.co/jzhMQTL/download-1.jpg'}
      urlLogo={route.params?.logo}
      // urlLogo={route.params?.logo}
      textoCentroFooter={"R$ " + validaTotalServico(servicos_selecionados)}
      semBotaoProximo={getConfigEmpresa?.ocultaBotoesRodapeServicos}
      semBotaoVoltar={getConfigEmpresa?.ocultaBotoesRodapeServicos}
      titulo_botao_voltar={
        getConfigEmpresa?.utilizaPwaSimples || getConfigEmpresa?.versaoPWA == 2
          ? "Logar"
          : "Voltar"
      }
      icon_voltar={
        getConfigEmpresa?.utilizaPwaSimples || getConfigEmpresa?.versaoPWA == 2
          ? "lock-open"
          : "arrow-with-circle-left"
      }
      onBotaoProximoClick={() => {
        if (
          servicos_selecionados.length == 0 &&
          !route.params?.servicos_adicionais
        ) {
          refModal.current.abrirModal("Selecione pelo menos um serviço!");
          return;
        }

        if (route.params?.servicos_adicionais) {
          navigation.navigate("Data", {
            servicos_selecionados: servicos_selecionados,
            ...route.params,
          });
          return;
        }

        if (
          getConfigEmpresa?.utilizaPwaSimples ||
          getConfigEmpresa?.versaoPWA == 2
        ) {
          navigation.navigate("Profissional", {
            servicos_selecionados: servicos_selecionados,
            ...route.params,
          });
          return;
        }

        navigation.navigate("Data", {
          servicos_selecionados: servicos_selecionados,
          ...route.params,
        });
      }}
      onBotaoVoltarClick={() => {
        if (
          getConfigEmpresa?.utilizaPwaSimples ||
          getConfigEmpresa?.versaoPWA == 2
        ) {
          navigation.navigate("TabNavigator", {
            screen: "Login",
          });
        } else {
          navigation.goBack();
        }
      }}
      filtro={renderFiltro()}
      ref={refModal}
    >
      <Box style={styles.mainContainer}>
        <FlatList
          data={servicos}
          renderItem={renderItem}
          ListEmptyComponent={
            buscando ? <SkeletonItens /> : renderEmptyComponent
          }
          keyExtractor={(item, index) => index.toString()}
          contentContainerStyle={{
            width: wp("98%"),
          }}
        />
      </Box>

      <ModalConfirmacao
        ref={confirmationRef}
        titulo={"Atenção"}
        mensagem={
          "Você tem direito a um brinde no mês do seu aniversário, deseja utilizá-lo?"
        }
        textoBotaoNegativo={"Não Utilizar"}
        textoBotaoPositivo={"Utilizar"}
        onConfirmNegativo={() => confirmationRef.current.fecharModal()}
        onConfirmPositivo={() => confirmarUtilizacaoBrinde()}
      />

      <ModalConfirmacao
        ref={confirmationRemoverBrindeRef}
        titulo={"Atenção"}
        mensagem={"Tem certeza que deseja remover esse brinde ?"}
        textoBotaoNegativo={"Cancelar"}
        textoBotaoPositivo={"Confirmar"}
        onConfirmNegativo={() =>
          confirmationRemoverBrindeRef.current.fecharModal()
        }
        onConfirmPositivo={() => confirmarRemoverBrinde()}
      />
    </Template>
  );
}
