import { StyleSheet } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


const createStyles = (coresPWA) =>

StyleSheet.create({
    mainContainer: {
        alignItems: 'center',
        marginTop: 3,
        width: '100%',
        justifyContent: 'center',
    },

    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: 9
    },
    boxTexto: {
        width: '100%',
        alignItems: 'center',
        padding: 2,
        textAlign: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    pressable: {
        width: '30%',
        height: hp('8%'), // Se o hp('8%') for uma unidade específica de um pacote, você terá que ajustar isso.
        backgroundColor: 'rgba(245, 245, 245, 0.3)', // Isso será sobrescrito dinamicamente.
        borderColor: '#D3D3D3',
        borderWidth: 2,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center'
    },
    text: {
        fontSize: RFPercentage(2.5) // Isso será sobrescrito dinamicamente.
    },
    textoDisp: {
 
        fontSize: RFPercentage(2.5),
        marginLeft: 10,
 
    },
 
    navigationContainer: {
        flexDirection: 'row',
        marginBottom: 3,
        justifyContent: 'space-between',
        width: '100%', // O wp('98%') pode precisar ser ajustado se você estiver usando um pacote específico.
        padding: 1.5,
        alignItems: 'center',
        paddingBottom: 10,
    },
    navButton: {
        // backgroundColor: 'trueGray.100',
        borderColor: '#D3D3D3',
        borderWidth: 1,
        padding: 10,
        // "variant" e "_pressed" são propriedades específicas do seu componente e não podem ser traduzidas diretamente para StyleSheet.
    },
    navButtonText: {
        fontSize: RFPercentage(2.5),
        // A cor será ajustada dinamicamente.
    },
    dateText: { 
        fontSize: RFPercentage(2.5),
        color: coresPWA.corFontePrimaria || '#000',
    },
    dateTextDia: {
        fontWeight: 'bold',
        color: coresPWA.corFontePrimaria || '#000',
    },


});


export default createStyles;