import { StyleSheet } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


export default StyleSheet.create({

    mainContainer: {
        // alignItems: 'center',
        marginTop: 3,
        width: '100%',
        justifyContent: '',
    },

    flexContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 20,
        width: '100%',
    },
    button: {
        backgroundColor: '#1976d2',
        minWidth: wp('25%'),  // certifique-se de que wp seja importado
        // height: hp('6%'), // certifique-se de que hp seja importado
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        padding: 11,
    },
    goBackButton: {
        // Se você quiser estilos específicos para o botão 'Voltar', pode adicioná-los aqui
    },
    buttonText: {
        color: '#fff', 
    }


});