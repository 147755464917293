import { StyleSheet } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


export default StyleSheet.create({
    footerContainer: {
        width: "100%",
        backgroundColor: '#000',
        marginTop: 5,
        paddingTop: 30,
        paddingBottom: 30,
        justifyContent: 'center',
    },
    joinUsText: {
        color: '#fff', 
        textAlign: 'center',
        fontWeight: '900',
    },
    connectText: {
        color: '#fff', 
        textAlign: 'center',
    },
    socialIconsContainer: {
        width: wp('100%'),
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 5,
    },
    socialIcon: {
        marginLeft: 10
    }
});