import { StyleSheet } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


const createStyles = (coresPWA) =>

    StyleSheet.create({
        flexContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderTopColor: 'rgba(0, 0, 0, 0.2)',
            borderTopWidth: 1,
            padding: 7
        },
        button: {
            backgroundColor: '#1976d2',
            minWidth: wp('25%')  // Por favor, garanta que wp esteja importado ou mova a lógica para o componente
        },
        buttonText: {
            color: '#fff',
            // fontSize: RFPercentage(2.5) // Novamente, garanta que RFPercentage esteja importado ou mova a lógica para o componente
        },

        styleButtonFinal: {
            width: "100%",
            backgroundColor: "#1976d2",
            // height: hp('7%'),
            borderRadius: 5,
            alignItems: 'center',
            justifyContent: 'center',
            padding: 11,
        },


        boxGeral: {
            flex: 1,
            justifyContent: 'space-between',
            backgroundColor: "#fff",
        },
        boxGeralScrollView: {
            flex: 1,
            alignItems: 'center',
            width: wp('100%'),
            marginTop: 3,
        },
        boxGeralScrollViewBox: {
            width: wp('90%')
        },
        boxGeralTitulo: {
            alignItems: 'center',
            padding: 7,
            textAlign: 'center',
            borderBottomColor: 'rgba(0, 0, 0, 0.2)',
            borderBottomWidth: 1,
        },
        textoConfirmado: {
            fontSize: RFPercentage(2.5),
            fontWeight: '700',
            color: '#000',
        },


        flexContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            height: hp('12%')
        },
        imageContainer: {
            // Coloque aqui os estilos específicos do container de imagem
        },
        innerBox: {
            // width: '80%',
            // height: hp('13%'),
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 100,
            padding: 20,
        },
        checkmark: {
            // fontSize: RFPercentage(7),
            fontWeight: 'bold',
            color: '#fff',
            marginTop: -5,

            // marginLeft: -5
        },


        // style comprovante

        imageContainer: {
            width: "30%",
            alignItems: 'center',
            justifyContent: 'center',
        },
        image: {
            resizeMode: "cover",
            height: 99,
            width: 99,
            borderRadius: 5,
        },
        textContainer: {
            width: "70%",
            alignItems: 'flex-start',
            justifyContent: 'center',
        },
        confirmedText: {
            color: coresPWA.corFontePrimaria || '#000',
            fontWeight: 'bold',
        },
        codeText: {
            color: coresPWA.corFontePrimaria || '#000',
            marginTop: 5,
        },

        heading: {
            fontWeight: 'bold',
            color: coresPWA.corFontePrimaria || '#000',
            borderBottomWidth: 0.1,
            marginTop: 20,
        },
        container: {
            width: "100%",
            justifyContent: 'center',
        },
        containerFinal: {
            width: "100%",
            justifyContent: 'center',
            marginTop: 15,
        },
        dateLabel: {
            color: coresPWA.corFontePrimaria || '#000',
            marginTop: 5,
        },
        dateLabelFinal: {
            fontSize: RFPercentage(2),
            color: coresPWA.corFontePrimaria || '#000',
            marginTop: 5,
            textAlign: 'justify',
        },
        dateValue: {
            fontWeight: 'bold',
            color: coresPWA.corFontePrimaria || '#000',
        },

    });

export default createStyles;