
import React, { useEffect, useRef, useState } from "react";
import { ScrollView, Dimensions } from "react-native";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { AspectRatio, Box, Center, Flex, Image, Skeleton, Text } from "native-base";
import { RFPercentage } from "react-native-responsive-fontsize";
import SkeletonInicio from "../Skeleton/Inicio";
import logoApp from '../../img/logo_simples_mini.png';

export default function HeaderInicio({ images, isMobile, nomeFantasia, logo }) {


    const { width: SCREEN_WIDTH } = Dimensions.get('window');

    const scrollViewRef = useRef<ScrollView | null>(null);
    const [currentIndex, setCurrentIndex] = useState(0);


    useEffect(() => { 
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        scrollViewRef.current?.scrollTo({
            x: currentIndex * SCREEN_WIDTH,
            animated: true,
        });
    }, [currentIndex]);

    return (
        <Box
            width={wp('100%')}
            height={isMobile ? hp('35%') : hp('60%')}
        >

            <ScrollView
                ref={scrollViewRef}
                horizontal
                pagingEnabled
                showsHorizontalScrollIndicator={false}
            >
                {images.map((source: string, index: number) => (
                    <Image
                        key={index}
                        source={{ uri: source }}
                        style={{ width: wp('100%'), height: '100%' }}
                        resizeMode="cover"
                        alt="Banner Inicial"
                    />
                ))}
            </ScrollView>

            <Flex
                position={'absolute'}
                bottom={0}
                width={wp('100%')}
                // height={'30%'} // tava hp('12%')
                backgroundColor={'rgba(0,0,0,0.5)'}
                // backgroundColor={'rgba(25, 118, 210, 0.5)'}
                direction={'row'} // Substituiu flexDirection
                alignItems={'center'}
                paddingLeft={5}
                paddingBottom={1}
                paddingTop={1}
            >
                {isMobile && <AspectRatio ratio={1} width="22%">
                    <Image
                        borderRadius={10}
                        source={{ uri: logo ? logo : logoApp }}
                        alt="Logo"
                        resizeMode="cover"
                    />
                </AspectRatio>}

                <Box
                    width={isMobile ? "75%" : "100%"}
                    height={"100%"}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    {/* alterado fonte */}
                    <Text style={{ textAlign: "center", color: '#fff', fontWeight: 'bold' }} fontSize="2xl">{nomeFantasia}</Text>
                    {/* <Text style={{ textAlign: "center", color: '#fff', fontSize: isMobile ? RFPercentage(4) : RFPercentage(2.5), fontWeight: 'bold' }}>{nomeFantasia}</Text> */}
                </Box>
            </Flex>

        </Box>
    );


}