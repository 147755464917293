import axios from 'axios'
import { URL_BASE } from '@env'

const URL_API =
  URL_BASE == null ? 'https://api.agendasimplificada.com.br/api' : URL_BASE


async function get(url) {
  try {

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${URL_API}${url}`
    }

    let response = await axios.request(config)

    return {
      status: true,
      data: response.data
    }
  } catch (error) {
    return {
      status: false,
      error_message: error.message,
      error_details: error.response?.data
    }
  }
}

async function post(url, params) {
  try {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${URL_BASE}${url}`,
      data: params
    }

    let response = await axios.request(config)

    return {
      status: true,
      data: response.data
    }
  } catch (error) {
    return {
      status: false,
      error_message: error.message,
      error_details: error.response?.data
    }
  }
}

async function put(url, params) {
  try {
    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${URL_BASE}${url}`,
      data: params
    }

    let response = await axios.request(config)

    return {
      status: true,
      data: response.data
    }
  } catch (error) {
    return {
      status: false,
      error_message: error.message,
      error_details: error.response?.data
    }
  }
}

export default {
  teste: () => {
    return get('/empresas/empresas-do-usuario')
  },
  dadosEmpresa: (subDomain) => {
    return get('/pwa/empresa/' + subDomain + '/dados')
  },
  versao: () => {
    return get('/publico/versao')
  },
  servicos: (id_empresa) => {
    return get('/pwa/servicos/' + id_empresa)
  },
  pacotesCliente: (id_empresa, id_cliente) => {
    return get('/pwa/pacotesCliente/' + id_empresa + '/' + id_cliente)
  },
  historicoCliente: (idCliente, idEmpresa) => {
    return get('/pwa/historicoCliente/' + idCliente + '/' + idEmpresa)
  },
  cancelarAgendamento: (idAgendamento) => {
    return put('/pwa/cancelarAgendamento/' + idAgendamento)
  },
  horarios_atendimento: (
    data,
    id_empresa,
    id_profissional,
    intervalo_agendamento
  ) => {
    return get(
      '/pwa/horarios_atendimento/' +
      data +
      '/' +
      id_empresa +
      '/' +
      id_profissional +
      '/' +
      intervalo_agendamento
    )
  },
  buscarClienteFinal: (idEmpresa) => {
    return get('/pwa/buscarclientefinal/' + idEmpresa)
  },
  dias_atendimento: (id_empresa) => {
    return get('/pwa/dias_atendimento/' + id_empresa)
  },
  profissionais: (id_empresa) => {
    return get('/pwa/profissionais/' + id_empresa)
  },
  buscaUtilizacaoServicoBrinde: (idCliente, getCodEmpresa) => {
    return get('/pwa/buscautilizacaoservicobrinde/' + idCliente + '/' + getCodEmpresa)
  },
  buscar_cliente: (telefone, senha, loginAdmin) => {
    //quando melhorar a rotina de login, melhorar essa parte
    if (!senha && loginAdmin) {
      senha = '123'
    }


    return get('/pwa/clientes/buscar/' + telefone + '/' + senha + '/' + loginAdmin)
  },
  clientes: (codEmpresa) => {
    console.log("codEnvia ", codEmpresa);
    return get('/pwa/clientes/buscartodos/' + codEmpresa)
  },
  alterar_senha: (idCliente, senha) => {
    return put('/pwa/clientes/alterarsenha', {
      idCliente,
      senha
    })
  },
  alterarDadosCliente: (idCliente, dataNascimento) => {
    return put('/pwa/clientes/alterardados', {
      idCliente,
      dataNascimento
    })
  },
  solicitarAlterarSenha: (telefone, codEmpresa) => {
    return put('/pwa/clientes/solicitarredefinicaosenha', {
      telefone,
      codEmpresa
    })
  },
  validarCodigo: (codigo, telefone) => {
    return put('/pwa/clientes/validarcodaltsenha', {
      codigo,
      telefone
    })
  },
  cadastrar_cliente: (telefone, nome, id_empresa, senha, dataNascimento) => {
    return post('/pwa/clientes/cadastrar', {
      telefone,
      nome,
      id_empresa_cadastro: id_empresa,
      senha,
      dataNascimento
    })
  },
  agendar: (
    dataSelecionada,
    horario_,
    clienteSelecionado,
    servicos_selecionados,
    funcionarioSelecionado,
    id_empresa,
    pacoteSelecionado
  ) => {
    return post('/pwa/agendar', {
      dataSelecionada,
      horarioSelecionado: {
        id: horario_.horario,
        name: horario_.horario
      },
      clienteSelecionado,
      servicos: servicos_selecionados,
      funcionarioSelecionado,
      empresaSelecionada: {
        id: id_empresa
      },
      validacoesPwa: true,
      pacoteSelecionado
    })
  }
}
