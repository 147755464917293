import React, { useEffect } from 'react';
import Routes from './src/routes';
import { NativeBaseProvider, Box, extendTheme, Text, useColorMode, useColorModeValue, Center, Button } from 'native-base';
import { ThemeProvider } from './ThemeProvider';

// import { Head } from 'expo-head';

document.documentElement.setAttribute('lang', 'pt-br');

export default function App() {


  const theme = extendTheme({
    config: {
      initialColorMode: 'Light', //Light ou Dark
    },
  });

  return (
    <ThemeProvider>
      <NativeBaseProvider theme={theme} >

        {/* <Head>
        <link rel="manifest" href={manifestPath} />
      </Head> */}

        <Box flex={1} safeArea display={'flex'} >
          {/* <UseColorMode /> */}
          <Routes />
        </Box>


      </NativeBaseProvider>
    </ThemeProvider>
  );
}
