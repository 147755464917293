import { StyleSheet } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


export default StyleSheet.create({

    mainContainer: {
        alignItems: 'center',
        marginTop: 1,
        width: '100%',
        justifyContent: 'center',
    },

    pressableItem: {
        width: '100%',
        height: 100,
        borderColor: 'rgba(0, 0, 0, 0.2)',
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        marginTop: 10,
    },
    containerFlex: {
        flexDirection: 'row',
        // direction: 'row',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 2
    },



    rightFlex: {
        direction: 'row',
        width: '25%',
        height: '100%',
        justifyContent: 'center',
    },
    leftFlex: {
        flexDirection: 'row',
        width: '75%',
        height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    boxCentroPressable: {
        width: '100%',
        marginTop: 3,
        alignItems: "center"
    },


});