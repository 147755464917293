import { StyleSheet } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


const createStyles = (coresPWA) =>

StyleSheet.create({

    mainContainer: {
        alignItems: 'center',
        marginTop: 3,
        width: '100%',
        justifyContent: 'center',
    },

    flexContainer: {
        flexDirection: 'row',
        marginBottom: 3,
        justifyContent: 'space-between',
        width: '100%',
        padding: 1.5,
        alignItems: 'center',
    },
    navigationButton: {
        backgroundColor: 'trueGray.100',
        borderColor: '#D3D3D3',
        borderWidth: 1,
        variant: 'unstyled',
        _pressed: {
            bg: 'rgba(189, 197, 218, 0.6)'
        }
    },
    monthText: {
        color: '#000',
        fontSize: RFPercentage(2.5)
    },
    arrowIcon: {
        fontSize: RFPercentage(2.5),
        color: 'black'
    },
    boxTexto: {
        width: '100%',
        alignItems: 'center',
        padding: 2,
        textAlign: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        marginTop: 5,
    },
    textoDisp: {
 
        fontSize: RFPercentage(2.5),
        marginLeft: 10,
        color: coresPWA.corFontePrimaria || '#000',


    }


});

export default createStyles;