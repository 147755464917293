
import React, { useEffect, useRef, useState } from "react";
import {  ScrollView, Dimensions } from "react-native";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Text, AspectRatio, Box, Flex, Image, Pressable, useColorMode } from "native-base";
import { RFPercentage } from "react-native-responsive-fontsize";
import { useTheme } from '../../../ThemeProvider.tsx';

export default function Menu({ itens_menu, onPress }) {

    const { primaryColor, isMobile, fontSizeMobile, fontSizeDesktop } = useTheme();

    const { height } = Dimensions.get('window');
    const { colorMode } = useColorMode();

    return (
        <Box
            width={wp('100%')}
            // height={height * 0.07}
        >
            <Flex
                width={wp('100%')}
                // height={isMobile ? height * 0.07 : height * 0.07}
                direction={'row'}
                alignItems={'center'}
                overflowX={'scroll'}
            >
                {itens_menu.map((item, index: number) => (

                    <Pressable
                        key={index}
                        style={{ 
                            marginLeft: 0.3, 
                            backgroundColor: colorMode == 'dark' ? '#000' : primaryColor ?? 'defaultColor' , 
                            borderTopWidth: colorMode == 'dark' ? 1 : 0,
                            borderTopColor: colorMode == 'dark' ? '#fff' : 'none',
                            minWidth: '30%', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            paddingTop: 11, 
                            paddingBottom: 11 
                        }}
                        // style={{ marginLeft: 0.3, backgroundColor: primaryColor ?? 'defaultColor', minWidth: '30%', height: height * 0.07, justifyContent: 'center', alignItems: 'center' }}
                        onPress={onPress}
                    >
                        <Text style={{ color: '#fff'}} fontSize="sm"  >{item.nome_menu}</Text>
                        {/* <Text style={{ color: '#fff', fontSize: !isMobile ? RFPercentage(fontSizeMobile - 0.8) : RFPercentage(fontSizeDesktop) }}>{item.nome_menu}</Text> */}
                    </Pressable>


                ))}
            </Flex>
        </Box>


    );


}