
import React, { useEffect, useRef, useState } from "react";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { AspectRatio, Box, Flex, HStack, Image, Pressable, Skeleton } from "native-base";
import { RFPercentage } from "react-native-responsive-fontsize";
import styles from "./styles";

export default function SkeletonHorario({ }) {


    return (
        <Flex style={styles.skeletonContainerFlex}>
            <Box width={'100%'}>


                {/* <HStack space="2" style={styles.hStack}>
                    <Skeleton h="16" flex="2" borderRadius={4} />
                </HStack> */}

                {[...Array(5)].map((_, rowIndex) => (
                    <Flex key={rowIndex} style={styles.flexitensSketon}>
                        {[...Array(3)].map((_, colIndex) => (
                            <Skeleton key={colIndex}
                                style={styles.itemSkeleton}
                            />
                        ))}
                    </Flex>
                ))}

            </Box>
        </Flex>
    );


}