import { View, Text, TouchableOpacity } from "react-native";
import React, { useState } from "react";
import { Box, Flex } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { RFPercentage } from "react-native-responsive-fontsize";
import createStyles from "./styles";
import { useTheme } from "../../../../../ThemeProvider";

const Menu: React.FC = () => {
  const { configEmpresa } = useTheme();

  const [getConfigEmpresa, setConfigEmpresa] = useState<any>(
    JSON.parse(configEmpresa)
  );

  const styles = createStyles(getConfigEmpresa.coresPWA); // gere os estilos dinâmicos

  return (
    <Flex
      direction="row"
      style={{
        width: "100%",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 2,
      }}
    >
      <TouchableOpacity style={styles.boxBotao}>
        <MaterialCommunityIcons
          name="calendar-clock"
          size={RFPercentage(10)}
          color={getConfigEmpresa.coresPWA.corIconesMenu}
        />

        <Text style={styles.corTexto}>Agendar</Text>
      </TouchableOpacity>

      <TouchableOpacity style={styles.boxBotao}>
        <MaterialCommunityIcons
          name="calendar-edit"
          size={RFPercentage(10)}
          color={getConfigEmpresa.coresPWA.corIconesMenu}
        />

        <Text style={styles.corTexto}>Meus Agendamentos</Text>
      </TouchableOpacity>
    </Flex>
  );
};

export default Menu;
