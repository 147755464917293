import { StyleSheet } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


const createStyles = (coresPWA) =>

StyleSheet.create({
    mainContainer: {
        alignItems: 'center',
        marginTop: 3,
        width: '100%',
        justifyContent: 'center',
    },

    labelText: { 
        fontWeight: 'bold',
        // color: '#000',
        marginRight: 10,
        color: coresPWA.corFontePrimaria || '#000',
    },
    dataText: { 
        fontWeight: '400',
        // color: '#000',
        color: coresPWA.corFontePrimaria || '#000',
    },
    flexContainer: { 
        width: "100%",
        display: 'flex', // substituindo 'display' por 'flex' que é a propriedade correta
        flexDirection: 'row', // substituindo 'direction' por 'flexDirection' que é a propriedade correta
        borderBottomColor: 'rgba(0, 0, 0, 0.2)',
        borderBottomWidth: 1
    },

    labelHorarioText: { 
        fontWeight: 'bold',
        // textTransform: 'uppercase',
        // letterSpacing: 1,
        // color: '#000',
        marginRight: 10,
        color: coresPWA.corFontePrimaria || '#000',
    },
    dataHorarioText: { 
        fontWeight: '400',
        // color: '#000',
        color: coresPWA.corFontePrimaria || '#000',
    },
    flexHorarioContainer: { 
        width: "100%",
        marginTop: 15,
        flexDirection: 'row',
        // borderBottomColor: 'rgba(0, 0, 0, 0.2)',
        borderBottomWidth: 1
    },

    profissionalLabel: { 
        fontWeight: 'bold',
        // color: '#000',
        marginRight: 10,
        color: coresPWA.corFontePrimaria || '#000',
    },
    profissionalContainer: {
        marginTop: 15,
        // borderBottomColor: 'rgba(0, 0, 0, 0.2)',
        borderBottomWidth: 1,
        paddingBottom: 5,
        width: '100%'
    },
    innerBox: {
        width: '100%',
        height: 75,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        marginTop: 10,
    },
    flexImageContainer: {
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    professionalImage: {
        height: 80,
        width: wp('25%'),
        borderRadius: 10,
    },
    professionalName: {
        // color: '#000',
        fontSize: RFPercentage(2.5),
        marginLeft: 10,
        width: wp('80%'),
        color: coresPWA.corFontePrimaria || '#000',
    },

    
    flexContainerDadosPessoais: {
        width: '100%',
        marginTop: 15,
        flexDirection: 'row',
        borderBottomColor: 'rgba(0, 0, 0, 0.2)',
        borderBottomWidth: 1
    },

    textLinhaPessoa: { 
        fontWeight: '700',
        // color: '#000',
        color: coresPWA.corFontePrimaria || '#000',
    },

    servicosContainer: {
        paddingTop: 3,
        // borderBottomColor: 'rgba(0, 0, 0, 0.2)',
        borderBottomWidth: 1,
        paddingBottom: 1,
        width: '100%'
    },
    servicosLabel: { 
        fontWeight: 'bold',
        // color: '#000',
        marginRight: 10,
        color: coresPWA.corFontePrimaria || '#000',
    },
    itemContainer: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
    },
    flexContainer: {
        flexDirection: 'row',
        width: '100%',
        // justifyContent: 'space-between',
        alignItems: 'center',
    },
    itemNome: {
        // color: '#000', 
        color: coresPWA.corFontePrimaria || '#000',
    },
    itemValor: {
        // color: '#000', 
        color: coresPWA.corFontePrimaria || '#000',
    },
    footerFlexContainer: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 5, 
    },
    footerText: {
        color: coresPWA.corFontePrimaria || '#000', 
    },
    footerBoldText: {
        color: coresPWA.corFontePrimaria || '#000', 
        fontWeight: '700',
    }
   
});

export default createStyles;