import React, { useState } from "react";
import { Input, Text, Icon, Pressable, Box, useColorMode } from "native-base";
import { Dimensions } from "react-native";
import { Entypo, MaterialIcons } from "@expo/vector-icons";
import { RFPercentage } from "react-native-responsive-fontsize";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";

interface InputProps {
  color: string;
  icon?: string;
  value: string;
  onChangeText: (text: string) => void;
  placeholder: string;
  type?: string;
  width?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  label?: string;
  disabled?: boolean;
  styleBox?: any;
}

function input({
  colorItem,
  corBordaFiltro,
  icon,
  value,
  onChangeText,
  placeholder,
  type,
  width,
  onBlur,
  onFocus,
  label,
  disabled,
  styleBox,
}: InputProps) {
  const [show, setShow] = useState(false);

  const { colorMode } = useColorMode();

  return (
    <Box width={width ? width : wp("100%")} style={styleBox}>
      {label && (
        <Text
          bold
          fontSize={RFPercentage(2.5)}
          color={colorItem ? colorItem : "muted.700"}
        >
          {label}
        </Text>
      )}
      <Input
        width={"100%"}
        isDisabled={disabled}
        borderRadius={5}
        borderColor={corBordaFiltro ? corBordaFiltro : "muted.700"}
        color={colorItem ? colorItem : "muted.700"}
        size="2xl"
        keyboardType={type == "number" ? "numeric" : "default"}
        value={value ? value.toString() : value}
        type={type == "password" ? (show ? "text" : "password") : "text"}
        onChangeText={(value) => onChangeText(value)}
        onFocus={() => {
          if (onFocus) {
            onFocus();
          }
        }}
        InputLeftElement={
          icon && (
            <Icon
              as={<MaterialIcons name={icon} />}
              size={5}
              ml="2"
              color={colorItem ? colorItem : "muted.400"}
            />
          )
        }
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
        }}
        InputRightElement={
          type == "password" && (
            <Pressable onPress={() => setShow(!show)}>
              <Icon
                as={
                  <MaterialIcons
                    name={show ? "visibility" : "visibility-off"}
                  />
                }
                size={5}
                mr="2"
                color={colorItem ? colorItem : "muted.400"}
              />
            </Pressable>
          )
        }
        placeholderTextColor={colorItem ? colorItem : "muted.400"}
        placeholder={placeholder}
      />
    </Box>
  );
}

export default input;
