import { Box, HamburgerIcon, Menu, Pressable, Text } from "native-base";

import { TouchableOpacity, Dimensions } from "react-native";
import React, { useState, useRef, useEffect } from "react";
import { RFPercentage } from "react-native-responsive-fontsize";
import { useTheme } from "../../../../ThemeProvider";
import { FontAwesome5 } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';



const ItemMenu: React.FC = ({ onClick, onClose, onOpen }) => {



    const { primaryColor } = useTheme();



    return <Box  alignItems="center" marginLeft={1}>
        <Menu 
            style={{  }}
            onClose={() => onClose()}
            onOpen={() => onOpen()}


            trigger={triggerProps => {
                return <TouchableOpacity  {...triggerProps} style={{  }}  >
                    <HamburgerIcon size={"80%"} color={'#fff'} />
                </TouchableOpacity>

            }}>
            <Menu.Item
                onPress={() => onClick(1)}
            >
                <Ionicons name="arrow-back-circle-sharp" size={RFPercentage(5)} color={primaryColor ?? 'defaultColor'} /> <Text style={{ fontSize: RFPercentage(3) }}>Voltar</Text>
            </Menu.Item>
            <Menu.Item
                onPress={() => onClick(2)}
            >
                <Ionicons name="exit" size={RFPercentage(5)} color={primaryColor ?? 'defaultColor'} /> <Text style={{ fontSize: RFPercentage(3) }}>Sair</Text>
            </Menu.Item>
        </Menu>
    </Box >

}

export default ItemMenu;