import React, { useState } from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { NavigationContainer } from "@react-navigation/native";
import { Feather } from "@expo/vector-icons";

import Inicio from "./pages/Inicio/Index";
import Login from "./pages/AreaCliente/Login/Index";
import Servicos from "./pages/Agendamento/Servicos/Servicos";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Profissional from "./pages/Agendamento/Profissional/Profissional";

import Data from "./pages/Agendamento/Data/Data";
import Horarios from "./pages/Agendamento/Horarios/Horarios";
import Confirmacao from "./pages/Agendamento/Confirmacao/Confirmacao";
import DadosUsuario from "./pages/Agendamento/DadosUsuario/DadosUsuario";
import Comprovante from "./pages/Agendamento/Comprovante";
import { useTheme } from "../ThemeProvider.tsx";
import Menu from "./pages/AreaCliente/Menu/Index.tsx";
import Pacotes from "./pages/AreaCliente/Pacotes/Index";
import PacoteItens from "./pages/AreaCliente/Pacotes/Itens/Index";
import Historico from "./pages/AreaCliente/Historico/Index";
import Clientes from "./pages/AreaCliente/Clientes/Clientes"; 

const Tab = createBottomTabNavigator();
const Stack = createNativeStackNavigator();

export default function App() {
  const { primaryColor } = useTheme();

  const [tabKey, setTabKey] = useState(0);

  function InicioStack() {
    return (
      <Stack.Navigator>
        <Stack.Screen
          name="Servicos"
          component={Servicos}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Profissional"
          component={Profissional}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Data"
          component={Data}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Horarios"
          component={Horarios}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Confirmacao"
          component={Confirmacao}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Comprovante"
          component={Comprovante}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="DadosUsuario"
          component={DadosUsuario}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Menu"
          component={Menu}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Pacotes"
          component={Pacotes}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Historico"
          component={Historico}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="PacoteItens"
          component={PacoteItens}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Clientes"
          component={Clientes}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    );
  }

  function handleInicioPress() {
    setTabKey(tabKey + 1);
    window.location.reload();
  }

  function TabNavigator() {
    return (
      <Tab.Navigator>
        <Tab.Screen
          name="Início"
          key={tabKey}
          component={Inicio}
          options={{
            headerShown: false,
            tabBarIcon: ({ color }) => (
              <Feather
                name="home"
                size={20}
                color={primaryColor ?? "defaultColor"}
              />
            ),
            tabBarLabelStyle: {
              color: primaryColor ?? "defaultColor",
            },
            // tabBarStyle: { display: 'none'}
          }}
          listeners={({ navigation }) => ({
            tabPress: () => {
              handleInicioPress();
            },
          })}
        />
        <Tab.Screen
          name="Login"
          component={Login}
          options={{
            headerShown: false,
            tabBarIcon: ({ color }) => (
              <Feather
                name="user"
                size={20}
                color={primaryColor ?? "defaultColor"}
              />
            ),
            tabBarLabelStyle: {
              color: primaryColor ?? "defaultColor",
            },
          }}
        />
        {/* Outras telas que devem aparecer na tab bar */}
      </Tab.Navigator>
    );
  }

  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen
          name="TabNavigator"
          component={TabNavigator}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="InicioStack"
          component={InicioStack}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
