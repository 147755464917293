import { StyleSheet } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


const createStyles = (coresPWA) =>
    StyleSheet.create({

        boxContainer: {
            width: '14.28%',
            alignItems: 'center',
            paddingBottom: 3,
        },
        domingoText: {
            color: 'red',
        },
        normalText: {
            color: '#000',
        },


        boxContainerItem: {
            width: '14.28%',
            alignItems: 'center',
        },
        innerBox: {
            width: '100%',
            padding: 5,
        },
        pressableContainer: {
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 6,
            borderColor: '#D3D3D3',
            borderWidth: 1,
        },
        dateText: {
            fontSize: RFPercentage(2.5),
            borderBottomWidth: 1,
        },
        flexContainer: {
            flexDirection: 'row',
            marginBottom: 3,
            justifyContent: 'space-between',
            width: '100%',
            padding: 1.5,
            alignItems: 'center',
        },

        navigationButton: {
            backgroundColor: '#fff',
            borderColor: '#D3D3D3',
            borderWidth: 1,
            variant: 'unstyled',
            _pressed: {
                bg: 'rgba(189, 197, 218, 0.6)'
            }
        },

        monthText: {
            fontSize: RFPercentage(2.5),
            color: coresPWA.corFontePrimaria,
        },

        arrowIcon: {
            fontSize: RFPercentage(2.5),
            color: 'black'
        },


    });

export default createStyles;