import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { Dimensions, Platform } from "react-native";

interface ThemeContextType {
  primaryColor: string | null;
  brindeAniversarianteMes: boolean | null;
  mesAtual: number | null;
  isMobile: boolean;
  fontSizeMobile: number;
  fontSizeDesktop: number;
  getCodEmpresa: string | null;
  getIntervaloAgendamento: string | null;
  getLimiteAgendamentoFuturo: string | null;
  dataLimiteAgendamentoFuturo: string | null;
  configEmpresa: {} | null;
  cor_rgba: string | null;
  setPrimaryColor: (color: string) => void;
  setBrindeAniversarianteMes: (item: boolean) => void;
  setMesAtual: (item: number) => void;
  setCodEmpresa: (codEmpresa: string) => void;
  setIntervaloAgendamento: (intervaloAgendamento: string) => void;
  setLimiteAgendamentoFuturo: (intervaloAgendamento: string) => void;
  setDataLimiteAgendamentoFuturo: (intervaloAgendamento: string) => void;
  setConfigEmpresa: (config: {}) => void;
  // ... outros valores relacionados ao tema
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [primaryColor, setPrimaryColor] = useState<string | null>("#fff"); //#1976d2
  const [brindeAniversarianteMes, setBrindeAniversarianteMes] = useState<
    boolean | null
  >(false); //#1976d2
  const [mesAtual, setMesAtual] = useState<number | null>(0); //#1976d2
  const [getCodEmpresa, setCodEmpresa] = useState<string | null>("0"); //#1976d2
  const [getIntervaloAgendamento, setIntervaloAgendamento] = useState<
    string | null
  >("0"); //#1976d2
  const [getLimiteAgendamentoFuturo, setLimiteAgendamentoFuturo] = useState<
    string | null
  >("0"); //#1976d2
  const [dataLimiteAgendamentoFuturo, setDataLimiteAgendamentoFuturo] =
    useState<string | null>("0"); //#1976d2
  const [configEmpresa, setConfigEmpresa] = useState<{} | null>({
    permiteAgendarSemCadastro: false,
    utilizaPwaSimples: false,
    exibeTempoServicoPWA: false,
    exibeMenuSuperiorPWA: false,
    ocultaBotoesRodapeServicos: false,
    versaoPWA: 1,
    naoSolicitarDadosCompletosPWA: false,
    validaDiasValidadePacote: false,
    utilizaTemaEscuro: false,
    // intervaloComTempoServico: false,
    utilizaLayoutV2: false,
    ocultarHorariosIndisponiveis: false,
    agendaPacotePeloPwa: true,
    coresPWA: {
      corBordaTitulo: "#1976d2",
    },
  }); //#1976d2
  const [cor_rgba, setCor_rgba] = useState<string | null>("#fff"); //#92c2f2
  const [isMobile, setIsMobile] = useState<boolean>(true);
  const [fontSizeMobile, setFontSizeMobile] = useState<number>(2.3);
  const [fontSizeDesktop, setFontSizeDesktop] = useState<number>(1.8);

  useEffect(() => {
    if (Dimensions.get("window").width > 900) {
      // if (Platform.OS === 'web') {
      setIsMobile(false);
    }

    const cor_rgba = hexToRGBA(primaryColor, 0.9);
    setCor_rgba(cor_rgba);
  }, [primaryColor]);

  function hexToRGBA(hex: string, alpha = 1): string {
    // Remova o hash na frente se estiver presente
    hex = hex.replace(/^#/, "");

    // Analise o r, g, b valores
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  // ... carregue a cor na inicialização

  return (
    <ThemeContext.Provider
      value={{
        mesAtual,
        primaryColor,
        brindeAniversarianteMes,
        isMobile,
        fontSizeDesktop,
        fontSizeMobile,
        cor_rgba,
        getCodEmpresa,
        getIntervaloAgendamento,
        getLimiteAgendamentoFuturo,
        dataLimiteAgendamentoFuturo,
        configEmpresa,
        setPrimaryColor,
        setBrindeAniversarianteMes,
        setMesAtual,
        setCodEmpresa,
        setIntervaloAgendamento,
        setLimiteAgendamentoFuturo,
        setDataLimiteAgendamentoFuturo,
        setConfigEmpresa,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
