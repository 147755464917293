import AsyncStorage from '@react-native-async-storage/async-storage';


interface IStorageUtil {
    setItem(key: string, value: any): Promise<void>;   // Mudei o type de value para 'any'
    getItem<T>(key: string): Promise<T | null>;       // Adicionado um generic type T
    removeItem(key: string): Promise<void>;
}

const setItem: IStorageUtil['setItem'] = async (key, value) => {
    try {
        const jsonValue = JSON.stringify(value);    // Convertendo o valor para JSON string
        await AsyncStorage.setItem(key, jsonValue);
    } catch (error) {
        console.error("Error saving data to AsyncStorage:", error);
        throw error;
    }
};

const getItem: IStorageUtil['getItem'] = async <T>(key: string): Promise<T | null> => {
    try {
        const jsonValue = await AsyncStorage.getItem(key);
        if (jsonValue === null) return null;
        return JSON.parse(jsonValue);    // Convertendo a JSON string de volta para objeto
    } catch (error) {
        console.error("Error retrieving data from AsyncStorage:", error);
        throw error;
    }
};

const removeItem: IStorageUtil['removeItem'] = async (key) => {
    try {
        await AsyncStorage.removeItem(key);
    } catch (error) {
        console.error("Error removing data from AsyncStorage:", error);
        throw error;
    }
};

export default {
    setItem,
    getItem,
    removeItem
};