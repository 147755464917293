import { StyleSheet } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';


const createStyles = (coresPWA) =>

  StyleSheet.create({


    mainContainer: {
      alignItems: 'center',
      marginTop: 3,
      width: '100%',
      justifyContent: 'center',
    },

    pressableItem: {
      width: '100%',
      borderColor: 'rgba(0, 0, 0, 0.2)',
      borderWidth: 1,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 5,
      marginTop: 10,
    },
    containerFlex: {
      flexDirection: 'row',
      width: '100%',
      height: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 2
    },
    leftFlex: {
      flexDirection: 'row',
      width: '75%',
      height: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    imageItem: {
      height: 70,
      width: 70,
      borderRadius: 5,
    },

    itemTextWithMargin: {
      marginLeft: 10
    },
    rightFlex: {
      direction: 'row',
      width: '25%',
      height: '100%',
      justifyContent: 'center',
    },

    itemText: {
      color: '#000',
      fontSize: RFPercentage(2.3),
    },

    overlayBox: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.6)', // Cor apagada (aqui, usei um cinza escuro com 50% de transparência)
      borderRadius: 5,
      alignItems: 'center',
      justifyContent: 'center',
    },
    overlayText: {
      fontSize: RFPercentage(3.0),
      fontWeight: 'bold',
      color: '#fff',
    },
  });

export default createStyles;