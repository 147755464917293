import { StyleSheet } from "react-native";


const createStyles = (coresPWA) =>
 StyleSheet.create({

    boxBotao: { 
        width: '40%',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5, 
        borderRadius: 10,
        borderColor: coresPWA.corBordaItemMenu || '#000',
        borderWidth: 1,

    },

    corTexto: {
        color: coresPWA.corFontePrimaria || '#000',
    },


});

export default createStyles;