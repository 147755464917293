import React, { useState, useImperativeHandle, useRef } from "react";
import { Input, Text, Icon, Pressable, Box, Button } from "native-base";
import { ActivityIndicator, Dimensions, ViewBase } from "react-native";
import { Entypo, MaterialIcons } from "@expo/vector-icons";
import { RFPercentage } from "react-native-responsive-fontsize";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import Modal from 'react-native-modal';
import { View } from "react-native";

function ModalLoader ({ }, ref) {

  const [modalVisible, setModalVisible] = useState<boolean>(false);


  function abrirLoader() {  
    setModalVisible(true); 
  }

  function fecharLoader() { 
    
    setModalVisible(false);
  }

  const noAnimation = {
    from: { opacity: 1, translateY: 0 },
    to: { opacity: 1, translateY: 0 },
  };

 
  useImperativeHandle(ref, () => ({ abrirLoader, fecharLoader }));

  return (
    <Modal isVisible={true}
    // <Modal isVisible={modalVisible}
    animationIn={noAnimation}
    animationOut={noAnimation}
    animationInTiming={0} // Instantâneo
    animationOutTiming={0} // Instantâneo
    
    >
       {true && <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
       {/* {modalVisible && <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}> */}
         <ActivityIndicator size={RFPercentage(12)} color="#1976d2" />
         <Text fontSize={"3xl"} style={{ color: "#fff" }}>Carregando...</Text>
      </View> }
    </Modal>

  );
};


export default React.forwardRef(ModalLoader);
