import { StyleSheet } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

const createStyles = (coresPWA) =>

  StyleSheet.create({
    flexContainer: {
        flexDirection: 'row',
       
        borderTopColor: coresPWA.corBordaPrimaria || 'rgba(0, 0, 0, 0.2)',
        borderTopWidth: 1,
        padding: 7,
        width: '100%',
    },
    button: {
        backgroundColor: '#1976d2',
        minWidth: wp('25%'),  // Por favor, garanta que wp esteja importado ou mova a lógica para o componente
        // height: hp('7%'), // Por favor, garanta que hp esteja importado ou mova a lógica para o componente
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        padding: 11,
    },
    buttonText: {
        color: '#fff',
        // fontSize: RFPercentage(2.5) // Novamente, garanta que RFPercentage esteja importado ou mova a lógica para o componente
    },
    textFooter: { 
        alignSelf: "center",
        color: coresPWA.corFontePrimaria || '#000',
    },
    buttonTextModal: {
        color: '#fff',
        fontSize: RFPercentage(2)
    },
    boxModal: {
        width: "100%",
        alignItems: 'center',
        justifyContent: 'center',
    },
    boxGeral: {
        flex: 1,
        justifyContent: 'space-between',
        backgroundColor: coresPWA.corFundo || '#fff',
        alignItems: 'center',
    },
    boxGeralScrollView: {
        flex: 1,
        alignItems: 'center',
        width: wp('100%'),
        marginTop: 3,
    },
    boxGeralScrollViewFiltro: {
        alignItems: 'center',
        width: wp('97%'),
        marginTop: 10,
    },
    boxGeralScrollViewBox: {
        width: wp('97%'),
        marginTop: 3,
        alignItems: 'center',
    },
    boxGeralTitulo: {
        alignItems: 'center',
        padding: 7,
        textAlign: 'center', 
        borderBottomColor: coresPWA.corBordaPrimaria || 'rgba(0, 0, 0, 0.2)',
        borderBottomWidth: 1,
        width: '100%',
    },

    fontTitulo: {
        color: coresPWA.corFontePrimaria || '#000',  
    },
    // boxGeralTituloText: {
    //     color: '#000', 
    // },









    boxBanner: {
        width:'100%',
        height:150,
        
        borderBottomColor: 'rgba(0, 0, 0, 0.3)',
        borderBottomWidth: 2,
        borderTopColor: 'rgba(0, 0, 0, 0.3)',
        borderTopWidth: 1.5,
      },

});

export default createStyles;