import React, { useEffect, useRef, useState } from "react";
import { TouchableOpacity } from "react-native";
import { Box, Flex, Text, useColorMode } from "native-base";
import { RFPercentage } from "react-native-responsive-fontsize";
import { AntDesign } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import Api from "../../../../Api/Api";
import Template from "../../../template/Index.tsx";
import createStyles from "./styles";
import SkeletonHorario from "../../../components/Skeleton/Horario";
import { useTheme } from "../../../../ThemeProvider.tsx";

export default function Horarios({}) {
  const navigation = useNavigation();
  const route = useRoute();

  const [data_selecionada, setDataSelecionada] = useState<Date>();
  const [horarios, setHorarios] = useState<any>([]);
  const [buscando, setBuscando] = useState<Boolean>(false);

  const { getCodEmpresa, getIntervaloAgendamento, configEmpresa } = useTheme();

  const { colorMode } = useColorMode();
  const [getConfigEmpresa, setConfigEmpresa] = useState<any>(
    JSON.parse(configEmpresa)
  );

  const styles = createStyles(getConfigEmpresa.coresPWA);

  const refModal = useRef();

  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const abrev_dias_semana = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];

  useEffect(() => {
    setDataSelecionada(route.params.data_selecionada);
  }, []);

  useEffect(() => {
    setBuscando(true);
  }, [data_selecionada]);

  useEffect(() => {
    if (buscando && data_selecionada != undefined && data_selecionada != null) {
      getHorariosAtendimento();
    }
  }, [buscando]);

  async function getHorariosAtendimento() {
    if (data_selecionada) {
      const id_empresa = getCodEmpresa;

      let horarios = await Api.horarios_atendimento(
        data_selecionada,
        id_empresa,
        route.params.profissional_selecionado.id,
        getIntervaloAgendamento
      );

      if (!horarios.status) {
        refModal.current.abrirModal(
          "Erro ao buscar os horários de atendimento"
        );
        return;
      }
      console.log(
        "config se é por intervalo de tempo",
        getConfigEmpresa.intervaloComTempoServico
      );
      console.log(
        "profissional selecionado........ ",
        route.params.profissional_selecionado.intervaloComTempoServico
      );

      if (route.params.profissional_selecionado.intervaloComTempoServico) {
        //esse bloco é responsável por bloquear os horários anteriores ao agendamento existemte.
        // pega o tempo total gasto com os serviços selecionados, pega o horário bloqueado e subtrai o tempo total gasto, bloqueia o que não da tempo.

        const tempoTotalGasto = route.params.servicos_selecionados.reduce(
          (total, current) => {
            return total + current.tempo;
          },
          0
        );

        const retornaOsBloqueados = horarios.data.filter(
          (item: any) => item.disponivel == false
        );
        for (const hr of retornaOsBloqueados) {
          const [horas, minutos] = hr.horario.split(":").map(Number);
          const data = new Date();
          data.setHours(horas);
          data.setMinutes(minutos);
          data.setMinutes(data.getMinutes() - (tempoTotalGasto - 1));

          const novoHorarioString = `${String(data.getHours()).padStart(
            2,
            "0"
          )}:${String(data.getMinutes()).padStart(2, "0")}`;

          for (const item of horarios.data) {
            if (
              item.horario >= novoHorarioString &&
              item.horario <= hr.horario
            ) {
              item.disponivel = false;
            }
          }
        }

        // passa remover os horários que não estão disponíveis
        // horarios.data = horarios.data.filter((item: any) => item.disponivel == true);
      }

      if (getConfigEmpresa.ocultarHorariosIndisponiveis) {
        const servicosDisponiveis = horarios.data.filter(
          (item: any) => item.disponivel
        );
        horarios.data = servicosDisponiveis;
      }

      const qtd = 3;
      const result = horarios.data.reduce((total, current, index) => {
        const belongArrayIndex = Math.floor(index / qtd);
        if (!total[belongArrayIndex]) {
          total[belongArrayIndex] = [];
        }
        total[belongArrayIndex].push(current);
        return total;
      }, []);

      setHorarios(result);
      setBuscando(false);
    }
  }

  function renderCabecalhoData() {
    // valida se a data selecionada é igual a data atual
    if (!data_selecionada) {
      return;
    }

    const valida_data_igual =
      new Date().getDate() == data_selecionada.getDate() &&
      new Date().getMonth() == data_selecionada.getMonth() &&
      new Date().getFullYear() == data_selecionada.getFullYear();

    return (
      <Flex style={styles.navigationContainer}>
        <TouchableOpacity
          // <Button
          style={[
            styles.navButton,
            {
              backgroundColor: valida_data_igual
                ? "rgba(189, 197, 218, 0.6)"
                : getConfigEmpresa.coresPWA.corFundoBotoesAvancarHorario,
            },
          ]}
          disabled={valida_data_igual}
          onPress={() => {
            const data = new Date(data_selecionada);
            data.setDate(data.getDate() - 1);
            setDataSelecionada(data);
          }}
          // variant='unstyled' _pressed={{ bg: 'rgba(189, 197, 218, 0.6)' }}
        >
          <AntDesign
            name="arrowleft"
            size={RFPercentage(2.5)}
            style={[
              styles.navButtonText,
              {
                color: valida_data_igual ? "rgba(189, 197, 218, 0.6)" : "black",
              },
            ]}
          />
        </TouchableOpacity>

        <Text style={styles.dateText}>
          <Text style={styles.dateTextDia}>
            {valida_data_igual
              ? "Hoje"
              : abrev_dias_semana[data_selecionada.getDay()]}
          </Text>{" "}
          -{" "}
          {data_selecionada
            ? data_selecionada.getDate() +
              " " +
              meses[data_selecionada.getMonth()] +
              " " +
              data_selecionada.getFullYear()
            : ""}
        </Text>

        <TouchableOpacity
          style={[
            styles.navButton,
            {
              backgroundColor:
                getConfigEmpresa.coresPWA.corFundoBotoesAvancarHorario,
            },
          ]}
          // variant='unstyled' _pressed={{ bg: 'rgba(189, 197, 218, 0.6)' }}
          onPress={() => {
            const data = new Date(data_selecionada);
            data.setDate(data.getDate() + 1);
            setDataSelecionada(data);
          }}
        >
          <AntDesign
            name="arrowright"
            size={RFPercentage(2.5)}
            style={styles.navButtonText}
          />
        </TouchableOpacity>
      </Flex>
    );
  }

  function renderHorarios(item: any, index: number) {
    return (
      <Flex key={index} style={styles.container}>
        {item.map((item: any, index: number) => {
          return (
            <TouchableOpacity
              // <Pressable
              key={index}
              style={[
                styles.pressable,
                {
                  backgroundColor: item.disponivel
                    ? getConfigEmpresa.coresPWA.corDisponivelCalendario
                    : getConfigEmpresa.coresPWA
                        .corIndisponivelDisponivelCalendario
                    ? getConfigEmpresa.coresPWA
                        .corIndisponivelDisponivelCalendario
                    : "trueGray.100",
                },
              ]}
              onPress={() => {
                if (item.disponivel) {
                  navigation.navigate("DadosUsuario", {
                    ...route.params,
                    horarios_selecionado: item,
                    data_selecionada: data_selecionada,
                  });
                } else {
                  refModal.current.abrirModal("Horário não disponível");
                }
              }}
            >
              <Text
                style={{
                  color: item.disponivel
                    ? getConfigEmpresa.coresPWA.corFonteItemHorarios
                    : "rgba(189, 197, 218, 0.9)",
                  fontSize: RFPercentage(2.5),
                }}
              >
                {item.horario}
              </Text>
            </TouchableOpacity>
          );
        })}
      </Flex>
    );
  }

  return (
    <Template
      titulo={"Selecione o Horário"}
      // disabled_button_proximo={mensagemRodape != ''}
      onBotaoProximoClick={() => {
        refModal.current.abrirModal("Selecione um horário");
      }}
      ref={refModal}
      filtro={renderCabecalhoData()}
    >
      {buscando ? (
        <SkeletonHorario />
      ) : (
        <Box style={styles.mainContainer}>
          {horarios.map((item: any, index: number) => {
            return renderHorarios(item, index);
          })}
        </Box>
      )}

      {horarios.length == 0 &&
        !buscando &&
        data_selecionada != null &&
        data_selecionada != undefined && (
          <Box style={styles.boxTexto}>
            <AntDesign name="closecircle" size={RFPercentage(4)} color="red" />
            <Text
              style={[
                styles.textoDisp,
                {
                  color: getConfigEmpresa.coresPWA.corFontePrimaria,
                },
              ]}
            >
              {"Desculpe, sem horários disponíveis para o dia selecionado"}
            </Text>
          </Box>
        )}
    </Template>
  );
}
