import { Box, Flex, Image, Text } from "native-base";
import React, { useState, useRef, useEffect } from "react";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import styles from "./styles";
import { useTheme } from "../../../../../ThemeProvider.tsx";

import { RFPercentage } from "react-native-responsive-fontsize";
import { useNavigation, useRoute } from "@react-navigation/native";
import Template from "../../../../template/Index.tsx";
import { FlatList } from "react-native";
import { TouchableOpacity } from "react-native";
import ModalConfirmacao from "../../../../../components/ModalConfirmacao";

const PacoteItens: React.FC = () => {
  const { cor_rgba, configEmpresa } = useTheme();

  const refModal = useRef();
  const refModalConfirma = useRef();
  const route = useRoute();
  const navigation = useNavigation();

  const [pacoteSelecionado, setPacoteSelecionado] = useState<any>(null);
  const [setaSelecionaPacote, setSetaSelecionaPacote] = useState<any>(true);

  const [getConfigEmpresa, setConfigEmpresa] = useState<any>(
    JSON.parse(configEmpresa)
  );

  useEffect(() => {
    const pacote = route.params?.pacoteSelecionado;

    for (const item of pacote.pacoteVendaItens) {
      item.selecionado = false;
    }

    setPacoteSelecionado(pacote);
  }, []);

  useEffect(() => {
    if (pacoteSelecionado && setaSelecionaPacote) {
      setSetaSelecionaPacote(false);
      const pacoteSelec = pacoteSelecionado.pacoteVendaItens;

      for (const item of pacoteSelec) {
        onPressPacoteSelecionado(item);
      }
    }
  }, [pacoteSelecionado, setaSelecionaPacote]);

  function renderFiltro() {
    return (
      <Box>
        <Text
          style={{
            color: getConfigEmpresa.coresPWA.corFontePrimaria,
            textAlign: "center",
          }}
          fontSize={"md"}
        >
          Escolha abaixo os itens que deseja utilizar
        </Text>
        <Text
          style={{
            color: getConfigEmpresa.coresPWA.corFontePrimaria,
            textAlign: "center",
          }}
          fontSize={"md"}
        >
          {pacoteSelecionado?.pacote.descricao}
        </Text>
      </Box>
    );
  }

  function linhaPacote(item: any) {
    let desabilita = false;

    if (item.produto) {
      desabilita = true;
    }

    if (item.quantidadeUtilizada >= item.quantidade) {
      desabilita = true;
    }

    if (item.ilimitado) {
      desabilita = false;
    }

    return (
      <Flex style={styles.containerFlex}>
        <Box style={styles.leftFlex}>
          <Image
            source={{
              uri: "https://i.pinimg.com/originals/bf/5d/b5/bf5db5425b7823594844b3a110c58e0b.png",
            }}
            alt="Item"
            resizeMode="cover"
            style={styles.imageItem}
          />

          <Box
            style={{
              width: wp("40%"),
            }}
          >
            <Text
              style={[
                styles.itemTextWithMargin,
                { color: getConfigEmpresa.coresPWA.corFontePrimaria },
              ]}
            >
              {item.produto ? item.produto.descricao : item.servico.nome}
            </Text>

            {item.ilimitado && (
              <Text
                style={[
                  styles.itemTextWithMargin,
                  {
                    color: "green",
                    fontSize: RFPercentage(2.3),
                  },
                ]}
              >
                {"Ilimitado"}
              </Text>
            )}

            {!item.ilimitado && (
              <Text
                style={[
                  styles.itemTextWithMargin,
                  {
                    color: "grey",
                  },
                ]}
                fontSize={"sm"}
              >
                {"Qtde Direito: " + item.quantidade}
              </Text>
            )}

            {!item.ilimitado && (
              <Text
                style={[
                  styles.itemTextWithMargin,
                  {
                    color: "grey",
                    fontSize: RFPercentage(2.3),
                  },
                ]}
                fontSize={"sm"}
              >
                {(item.produto ? "Qtde Retirado: " : "Qtde Utilizado: ") +
                  item.quantidadeUtilizada}
              </Text>
            )}

            {item.produto && item.quantidade != item.quantidadeUtilizada && (
              <Text
                style={[
                  styles.itemTextWithMargin,
                  {
                    color: "green",
                    fontSize: RFPercentage(2.3),
                  },
                ]}
              >
                {"Retire seu produto na loja"}
              </Text>
            )}
          </Box>

          <Box
            style={{
              backgroundColor: item.selecionado ? "blue" : "red",
              borderRadius: 10,
            }}
          >
            <Text
              style={{
                color: "#fff",
              }}
            >
              {" "}
              {!item.selecionado ? "Não " : ""}Selecionado{" "}
            </Text>
          </Box>
        </Box>

        {desabilita && <Box style={styles.overlayBox}></Box>}
      </Flex>
    );
  }

  function onPressPacoteSelecionado(item: any) {
    if (!item.ilimitado) {
      if (item.produto) {
        return;
      }

      if (item.quantidadeUtilizada >= item.quantidade) {
        return;
      }
    }

    //setar statusSelecionado como true ou false

    const pacote = pacoteSelecionado;

    const itens = pacote.pacoteVendaItens;

    itens.map((item_) => {
      if (item_.id == item.id) {
        item_.selecionado = !item_.selecionado;
      }
    });

    setPacoteSelecionado({ ...pacote });
  }

  const renderItem = ({ item, index }) => (
    <TouchableOpacity
      key={index}
      style={[
        styles.pressableItem,
        {
          borderColor: item.selecionado
            ? cor_rgba ?? "defaultColor"
            : getConfigEmpresa.coresPWA.corBordaItens,
        }, //mais clara
      ]}
      onPress={() => onPressPacoteSelecionado(item)}
    >
      {linhaPacote(item)}
      {/* {buscando ? <SkeletonItens /> : linhaPacote(item)} */}
    </TouchableOpacity>
  );

  const renderEmptyComponent = () => (
    <Text
      style={{
        textAlign: "center",
        marginTop: 20,
        fontSize: RFPercentage(2.5),
      }}
    >
      Nenhum registro encontrado.
    </Text>
  );

  return (
    <Template
      titulo={"Direitos do Pacote"}
      onBotaoProximoClick={() => {
        const algum_selecionado = pacoteSelecionado?.pacoteVendaItens.some(
          (item) => {
            return item.selecionado;
          }
        );
        if (!algum_selecionado) {
          refModal.current.abrirModal(
            "Você precisa selecionar pelo menos um item, clique em NÃO SELECIONADO para selecionar o item."
          );
          return;
        }

        refModalConfirma.current.abrirModal();
      }}
      onBotaoVoltarClick={() => {
        navigation.goBack();
      }}
      filtro={renderFiltro()}
      ref={refModal}
    >
      <Box style={styles.mainContainer}>
        <FlatList
          data={pacoteSelecionado?.pacoteVendaItens}
          renderItem={renderItem}
          ListEmptyComponent={renderEmptyComponent}
          // ListEmptyComponent={buscando ? <SkeletonItens /> : renderEmptyComponent}
          keyExtractor={(item, index) => index.toString()}
          contentContainerStyle={{
            width: wp("98%"),
          }}
        />
      </Box>

      <ModalConfirmacao
        titulo={"Atenção"}
        ref={refModalConfirma}
        mensagem={"Deseja adicionar mais algum serviço?"}
        textoBotaoPositivo={"Sim"}
        textoBotaoNegativo={"Não"}
        onConfirmPositivo={() => {
          if (refModalConfirma.current) {
            refModalConfirma.current.fecharModal();
          }

          const pacotes = [];
          pacotes.push(pacoteSelecionado);

          navigation.navigate("Profissional", {
            pacoteSelecionado: pacotes,
            servicos_adicionais: true,
          });
        }}
        onConfirmNegativo={() => {
          if (refModalConfirma.current) {
            refModalConfirma.current.fecharModal();
          }

          const pacotes = [];
          pacotes.push(pacoteSelecionado);

          navigation.navigate("Profissional", {
            pacoteSelecionado: pacotes,
            servicos_adicionais: false,
          });
        }}
      />
    </Template>
  );
};

export default PacoteItens;
