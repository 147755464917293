
import React, { useEffect, useRef, useState } from "react";
import { TouchableOpacity } from "react-native";
import { Box, Button, Flex, Pressable, useColorMode, Text } from "native-base";
import { AntDesign } from '@expo/vector-icons';

import createStyles from "./styles";
import { useTheme } from '../../../ThemeProvider.tsx';


interface BotoesRodapeProps {
    dataSelecionada: Date;
    diasAtendimento: any[];
    onChange: (data: Date, valida_dia_menor_atual: boolean) => void;
    dataInicioCalendario?: Date;
}


export default function Calendario({ dataSelecionada, dataInicioCalendario, onChange, diasAtendimento }: BotoesRodapeProps) {

    const { primaryColor, configEmpresa } = useTheme();

    const { colorMode } = useColorMode();

    const [gruposDias, setGruposDias] = useState<any[]>([]);
    const [dataAtualExibindo, setDataAtualExibindo] = useState<Date>(dataInicioCalendario || new Date());

    const [getConfigEmpresa, setConfigEmpresa] = useState<any>(JSON.parse(configEmpresa));


    const styles = createStyles(getConfigEmpresa.coresPWA);

    const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
    ];

    const Calendar = () => {
        const date = dataAtualExibindo;
        const year = date.getFullYear();
        const month = date.getMonth();

        const primeiroDia = new Date(year, month, 1);

        // Último dia do mês
        const ultimoDia = new Date(year, month + 1, 0);

        // Array para armazenar todos os dias do mês
        let daysOfMonth = [];

        // Preenche o array com todos os dias do mês
        for (let i = primeiroDia.getDate(); i <= ultimoDia.getDate(); i++) {
            daysOfMonth.push(new Date(year, month, i));
        }



        const primeiroDiaWeek = primeiroDia.getDay();

        if (primeiroDiaWeek == 0) {
            for (let i = 1; i <= 6; i++) {
                daysOfMonth.unshift(new Date(year, month, primeiroDia.getDate() - i));
            }
        } else if (primeiroDiaWeek > 1) {
            for (let i = 1; i < primeiroDiaWeek; i++) {
                daysOfMonth.unshift(new Date(year, month, primeiroDia.getDate() - i));
            }
        }


        const groups = daysOfMonth.reduce((acc, cur, i) => {
            if (!(i % 7)) acc.push([]);
            acc[acc.length - 1].push(cur);
            return acc;
        }, []);


        const lastGroup = groups[groups.length - 1];

        if (lastGroup.length < 7) {
            const total_grupo = lastGroup.length;

            for (let i = 1; i <= 7 - total_grupo; i++) {


                lastGroup.push(new Date(year, month + 1, i));
            }
        }


        setGruposDias(groups);
    }

    useEffect(() => {
        Calendar();
    }, [dataAtualExibindo]);


    function itemCabecalho(titulo: string) {

        return (
            <Box
                style={[
                    styles.boxContainer,
                    {
                        backgroundColor: titulo === 'DOM' || titulo === 'SÁB'
                            ?  '#fff'
                            // ?  'rgba(237, 78, 9, 0.1)'
                            // ?  colorMode == 'dark' ? "#fff" : 'rgba(237, 78, 9, 0.1)'
                            : "#fff"
                    }
                ]}
            >
                <Text
                    style={titulo === 'DOM' ? styles.domingoText : styles.normalText}
                >
                    {titulo}
                </Text>
            </Box>
        );
    }

    function itemData(data: Date, index: number) {

        const dia_semana = data.getDay();
        let pertence_mes_atual = data.getMonth() == dataAtualExibindo.getMonth();
        const data_selecionada = dataSelecionada ? dataSelecionada.getDate() == data.getDate() && dataSelecionada.getMonth() == data.getMonth() && dataSelecionada.getFullYear() == data.getFullYear() : "";
        // validar se o dia selecionado é menor que o dia atual
        // const valida_dia_menor_atual = data.getDate() < new Date().getDate() && data.getMonth() == new Date().getMonth() && data.getFullYear() == new Date().getFullYear();

        const now = new Date();
        const valida_dia_menor_atual = data < new Date(now.getFullYear(), now.getMonth(), now.getDate());


        let bloqueia_dia = true;

        if (diasAtendimento && pertence_mes_atual) {

            bloqueia_dia = diasAtendimento.includes(dia_semana);

        }


        return (
            <Box
                style={[
                    styles.boxContainer,
                    {
                        backgroundColor: dia_semana === 0 || dia_semana === 6
                            ? 'rgba(237, 78, 9, 0.1)'
                            : "#fff"
                    }
                ]}
                key={index}
            >

                <Box
                    style={styles.innerBox}
                >

                    <TouchableOpacity
                        // <Pressable
                        style={[
                            styles.pressableContainer,
                            {
                                backgroundColor: data_selecionada ? primaryColor ?? 'defaultColor' : '#f4f5f9',
                                borderRadius: data_selecionada ? 30 : 3
                            }
                        ]}
                        onPress={() => {

                            if (!pertence_mes_atual) {
                                return;
                            }

                            onChange(data, valida_dia_menor_atual);
                        }}

                        iant='unstyled' _pressed={{ bg: 'rgba(189, 197, 218, 0.6)' }}

                    >

                        <Text
                            style={[
                                styles.dateText,
                                {
                                    color: (pertence_mes_atual && bloqueia_dia) ? data_selecionada ? "#fff" : '#000' : "rgba(189, 197, 218, 0.6)",
                                    borderBottomColor: (pertence_mes_atual && bloqueia_dia) ? 'green' : "rgba(0, 128, 0, 0.4)"
                                }
                            ]}

                        >{data.getDate()}</Text>
                    </TouchableOpacity>
                </Box>

            </Box>

        );
    }


    function botaoMesCalendario() {


        return (
            <Flex style={styles.flexContainer}>
                <Button
                    style={[styles.navigationButton]}
                    onPress={() => {
                        setDataAtualExibindo(new Date(dataAtualExibindo.getFullYear(), dataAtualExibindo.getMonth() - 1, 1));
                    }}
                >
                    <AntDesign name="arrowleft" style={styles.arrowIcon} />
                </Button>

                <Text style={[styles.monthText]}>
                    {meses[dataAtualExibindo.getMonth()] + " " + dataAtualExibindo.getFullYear()}
                </Text>

                <Button
                    style={styles.navigationButton}
                    onPress={() => {
                        setDataAtualExibindo(new Date(dataAtualExibindo.getFullYear(), dataAtualExibindo.getMonth() + 1, 1));
                    }}
                >
                    <AntDesign name="arrowright" style={styles.arrowIcon} />
                </Button>
            </Flex>
        )
    }

    return (
        <Box
            width={'100%'}
            alignItems={'center'}
        >

            {botaoMesCalendario()}

            <Flex
                direction={'row'}
                width={'100%'}
                marginTop={5}
            >
                {itemCabecalho("SEG")}
                {itemCabecalho("TER")}
                {itemCabecalho("QUAR")}
                {itemCabecalho("QUI")}
                {itemCabecalho("SEX")}
                {itemCabecalho("SÁB")}
                {itemCabecalho("DOM")}

            </Flex>

            {gruposDias.map((grupo, index: number) => (

                <Flex
                    key={index}
                    direction={'row'}
                    width={'100%'}
                >
                    {grupo.map((item, index: number) => (
                        itemData(item, index)
                    ))
                    }

                </Flex>
            ))}
        </Box>

    );


}