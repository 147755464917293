import { Box, Button, Image, Text, useColorModeValue } from "native-base";
import React, { useState, useRef, useEffect } from "react";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import createStyles from "./styles";
import Input from "../../../../components/Input";
import { useTheme } from "../../../../ThemeProvider.tsx";
import pessoaDesconhecida from "../../../img/hacker.png";
import Api from "../../../../Api/Api";
import { useNavigation, useRoute } from "@react-navigation/native";
import StorageUtil from "../../../../Utils";
import Loader from "../../../../components/Loader";
import { StackNavigationProp } from "@react-navigation/stack";
import ModalConfirmacao from "../../../../components/ModalConfirmacao";
import { Pressable, TouchableOpacity } from "react-native";
import { CommonActions } from "@react-navigation/native";

const Login: React.FC = () => {
  const { primaryColor, getCodEmpresa, configEmpresa } = useTheme();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const [cleaned, setCleaned] = useState("");
  const [textoModal, setTextoModal] = useState<String>("");
  const [cliente, setCliente] = useState<any>({});
  const [tipoOperacao, setTipoOperacao] = useState<number>(1);
  // 1 entrar normal,
  // 2 cadasrefLoadertrar,
  // 3 login com senha,
  // 4 alterar senha
  // 6 agendar sem cadastro -- não existe nessa tela, pois aparece o item para pessoa clicar.
  // 7 utiliza sistema apenas com telefone e nome - mas cadastra caso não exista
  const [nome, setNome] = useState<String>("");
  const [senha, setSenha] = useState<String>("");
  const [confirmaSenha, setConfirmaSenha] = useState<String>("");
  const [telefone, setTelefone] = useState<String>("");
  const [codigoRedenficao, setCodigoRedenficao] = useState<String>("");
  const [loader, setLoader] = useState<Boolean>(false);
  const [tipoOperacaoRedSenha, setTipoOperacaoRedSenha] = useState<number>(0); // 1 enviar codigo, 2 confirmar codigo
  const [dataNascimento, setDataNascimento] = useState<String>("");
  const [getConfigEmpresa, setConfigEmpresa] = useState<any>(
    JSON.parse(configEmpresa)
  );

  const styles = createStyles(getConfigEmpresa.coresPWA);

  const [clienteSimples, setClienteSimples] = useState<any>({});

  const [numeroInternacional, setNumeroInternacional] =
    useState<boolean>(false);

  // const backgroundColor = useColorModeValue('white', 'black');
  const backgroundColor = getConfigEmpresa.coresPWA.corFundo;

  const refModal = useRef<any>(null);
  const refLoader = useRef<any>(null);
  const confirmationRef = useRef<any>(null);

  const route = useRoute();

  useEffect(() => {
    getClients();
    setTipoOperacaoRedSenha(0);
  }, []);

  async function getClients() {
    const value = await StorageUtil.getItem("cliente");

    if (value && value.id > 0) {
      if (getConfigEmpresa.naoSolicitarDadosCompletosPWA) {
        await buscarClienteSoTelefone(value.telefone);
      } else if (!value.senha) {
        return;
      } else {
        await buscarClienteSenha(value.telefone, value.senha, value.loginAdmin);
      }
    }
  }

  function onchange(text: String) {
    if (numeroInternacional) {
      setTelefone(text);
      return;
    }

    text = text.replace(/\D/g, "");

    let newCleaned = text.replace(/\D/g, "").substring(0, 11); // remove non-digit and limit
    if (newCleaned !== cleaned) {
      setCleaned(newCleaned);

      let formatted = newCleaned;
      if (newCleaned.length >= 2) {
        formatted = `(${newCleaned.substring(0, 2)}) ${newCleaned.substring(
          2
        )}`;
      }
      if (newCleaned.length >= 7) {
        formatted = `(${newCleaned.substring(0, 2)}) ${newCleaned.substring(
          2,
          7
        )}-${newCleaned.substring(7)}`;
      }

      setTelefone(formatted);
    } else {
      setTelefone(text);
    }
  }

  function onchangeDtNascimento(text: String) {
    text = text.replace(/\D/g, "");

    let newCleaned = text.replace(/\D/g, "").substring(0, 8); // remove non-digit and limit
    if (newCleaned !== cleaned) {
      setCleaned(newCleaned);

      let formatted = newCleaned;
      if (newCleaned.length >= 2) {
        formatted = `${newCleaned.substring(0, 2)}/${newCleaned.substring(2)}`;
      }
      if (newCleaned.length >= 4) {
        formatted = `${newCleaned.substring(0, 2)}/${newCleaned.substring(
          2,
          4
        )}/${newCleaned.substring(4)}`;
      }

      setDataNascimento(formatted);
    } else {
      setDataNascimento(text);
    }
  }

  function proximaPagina(idCliente: number) {
    navigation.navigate("InicioStack", {
      screen: "Menu",
      params: {
        idCliente: idCliente,
      },
    });
  }

  async function buscarClienteSenha(
    telefone_?: string,
    senha_?: any,
    loginAdmin?: boolean
  ) {
    const telefone_limpo = (telefone_ ? telefone_ : telefone).replace(
      /\D/g,
      ""
    );

    if (!isTelefoneValido(telefone_limpo)) {
      abrirModal("Informe um telefone válido");
      return;
    }

    exibirLoader();

    // depois melhorar isso aqui, se o loginAdmin for true, solicitar o login do admin, deverá na tela anterior salvar o login do admin tbm.
    const cliente = await Api.buscar_cliente(
      telefone_ ? telefone_ : telefone,
      senha_ ? senha_ : senha,
      loginAdmin ? loginAdmin : false
    );

    if (!cliente.status) {
      fecharLoader();
      abrirModal(
        cliente.error_details
          ? cliente.error_details.message
          : "Erro ao buscar cliente"
      );
      return;
    }

    if (cliente.data == "") {
      setTipoOperacao(1);
      fecharLoader();
      return;
    }

    const dados_cliente = cliente.data;

    if (dados_cliente.alterarSenha) {
      await StorageUtil.removeItem("cliente");

      setTipoOperacao(4);
      fecharLoader();
      setCliente(dados_cliente);
      setTelefone(dados_cliente.telefone);
      abrirModal("Informe uma nova senha");
      return;
    }

    dados_cliente.senha = senha_ ? senha_ : senha;

    await StorageUtil.setItem("cliente", dados_cliente);

    if (route.params?.retornaLogin) {
      window.location.href = "http://pwa.localhost:19006/";
      return;
    }

    fecharLoader();

    if (route.params && route.params?.forcaLogin) {
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: "TabNavigator", params: { screen: "Inicio" } }],
        })
      );

      return;
    }

    proximaPagina(dados_cliente.id);
  }

  async function alterarSenha() {
    if (senha != confirmaSenha) {
      abrirModal("As senhas não conferem");
      return;
    }
    exibirLoader();

    const resultadoAlteracao = await Api.alterar_senha(cliente.id, senha);

    if (!resultadoAlteracao.status) {
      fecharLoader();
      abrirModal(
        resultadoAlteracao.error_details.message || "Erro ao alterar senha"
      );
      return;
    }

    const { telefone } = resultadoAlteracao.data;

    await buscarClienteSenha(telefone, senha);
  }

  async function buscarClienteSoTelefone(
    telefone_?: String,
    dadosCorretos?: boolean
  ) {
    if (!telefone_) {
      abrirModal("Informe um telefone válido");
      return;
    }

    const telefone_limpo = telefone_.replace(/\D/g, "");

    if (!isTelefoneValido(telefone_limpo)) {
      abrirModal("Informe um telefone válido");
      return;
    }

    const cliente = await Api.buscar_cliente(telefone_);

    if (!cliente.status) {
      fecharLoader();
      abrirModal("Erro ao buscar cliente");
      return;
    }

    if (cliente.data == "") {
      // se não existe o cliente mais então manda logar normal
      setTipoOperacao(1);
      return;
    }
    await StorageUtil.setItem("cliente", cliente.data);
    proximaPagina(cliente.data.id);
    fecharLoader();
  }

  function isTelefoneValido(telefone: String) {
    if (numeroInternacional) {
      return true;
    }

    return telefone.length >= 11;
  }

  function isNomeValido(nome: String) {
    return nome.length >= 4 && nome.indexOf(" ") !== -1;
  }

  function exibirLoader() {
    // refLoader.current.abrirLoader();
    setLoader(true);
  }
  function fecharLoader() {
    // refLoader.current.fecharLoader();
    setLoader(false);
  }

  async function cadastrarCliente() {
    const telefone_limpo = telefone.replace(/\D/g, "");

    if (!isTelefoneValido(telefone_limpo)) {
      abrirModal("Informe um telefone válido");
      return;
    }

    if (!isNomeValido(nome)) {
      abrirModal("Informe seu nome completo");
      return;
    }

    if (senha != confirmaSenha) {
      abrirModal("As senhas não conferem");
      return;
    }

    if (dataNascimento.length < 10) {
      abrirModal("Informe uma data de nascimento válida");
      return;
    }

    const dataNascimentoSplit = dataNascimento.split("/");
    const dia = parseInt(dataNascimentoSplit[0]);
    const mes = parseInt(dataNascimentoSplit[1]);
    const ano = parseInt(dataNascimentoSplit[2]);

    if (dia < 1 || dia > 31) {
      abrirModal("Informe um dia de nascimento válido");
      return;
    }

    if (mes < 1 || mes > 12) {
      abrirModal("Informe um mês de nascimento válido");
      return;
    }

    if (ano < 1930) {
      abrirModal("Informe um ano de nascimento válido");
      return;
    }

    if (senha == "" || confirmaSenha == "") {
      abrirModal("Informe uma senha");
      return;
    }

    exibirLoader();
    const resultadoCadastro = await Api.cadastrar_cliente(
      telefone,
      nome,
      getCodEmpresa,
      senha,
      dataNascimento
    );

    if (!resultadoCadastro.status) {
      fecharLoader();
      abrirModal(
        resultadoCadastro.error_details.message || "Erro ao cadastrar cliente"
      );
      return;
    }

    const dados_cliente = resultadoCadastro.data;
    dados_cliente.senha = senha;

    await StorageUtil.setItem("cliente", dados_cliente);

    fecharLoader();
    proximaPagina(dados_cliente.id);
  }

  async function cadastrarClienteTelefoneNome() {
    const telefone_limpo = telefone.replace(/\D/g, "");

    if (!isTelefoneValido(telefone_limpo)) {
      abrirModal("Informe um telefone válido");
      return;
    }

    if (!isNomeValido(nome)) {
      abrirModal("Informe seu nome completo");
      return;
    }

    exibirLoader();
    const resultadoCadastro = await Api.cadastrar_cliente(
      telefone,
      nome,
      getCodEmpresa
    );

    if (!resultadoCadastro.status) {
      fecharLoader();
      abrirModal(
        resultadoCadastro.error_details.message || "Erro ao cadastrar cliente"
      );
      return;
    }

    const dados_cliente = resultadoCadastro.data;
    await StorageUtil.setItem("cliente", dados_cliente);

    fecharLoader();
    proximaPagina(dados_cliente);
  }

  // Inicio da parte do redefinir senha

  async function enviarCodigo() {
    exibirLoader();
    const telefone_limpo = telefone.replace(/\D/g, "");

    if (!isTelefoneValido(telefone_limpo)) {
      abrirModal("Informe um telefone válido");
      fecharLoader();
      return;
    }

    const resultadoRedefinicaoSenha = await Api.solicitarAlterarSenha(
      telefone,
      getCodEmpresa
    );

    if (!resultadoRedefinicaoSenha.status) {
      abrirModal(
        resultadoRedefinicaoSenha.error_details.message ||
          "Erro ao solicitar alteração de senha"
      );
      fecharLoader();
      return;
    }
    fecharLoader();
    setTipoOperacaoRedSenha(2);
  }

  async function validarCodigo() {
    exibirLoader();

    const codigo_limpo = codigoRedenficao.replace(/\D/g, "");

    if (!codigo_limpo || codigo_limpo.length < 4) {
      abrirModal("Código inválido");
      fecharLoader();
      return;
    }

    const resultadoRedefinicaoSenha = await Api.validarCodigo(
      codigo_limpo,
      telefone
    );

    if (!resultadoRedefinicaoSenha.status) {
      abrirModal(
        resultadoRedefinicaoSenha.error_details.message ||
          "Erro ao solicitar alteração de senha"
      );
      fecharLoader();
      return;
    }
    fecharLoader();
    setTipoOperacaoRedSenha(0);
    entrar();
  }

  //fim da parte do redefinir senha

  async function entrar() {
    const telefone_limpo = telefone.replace(/\D/g, "");

    if (!isTelefoneValido(telefone_limpo)) {
      abrirModal("Informe um telefone válido");
      return;
    }

    if (tipoOperacao == 2) {
      // cadastrar cliente
      cadastrarCliente();
      return;
    }
    if (tipoOperacao == 7 && !cliente.id) {
      // cadastrar cliente só com telefone e nome
      cadastrarClienteTelefoneNome();
      return;
    }

    if (tipoOperacao == 3) {
      // login normal com senha
      buscarClienteSenha();
      return;
    }
    if (tipoOperacao == 4) {
      // alterar senha
      alterarSenha();
      return;
    }

    exibirLoader();

    const clienteRet = await Api.buscar_cliente(telefone);
    if (!clienteRet.status) {
      fecharLoader();
      abrirModal("Erro ao buscar cliente");
      return;
    }
    if (getConfigEmpresa.naoSolicitarDadosCompletosPWA) {
      if (clienteRet.data != "") {
        await buscarClienteSoTelefone(clienteRet.data.telefone);
      }
      setTipoOperacao(7);
    } else if (clienteRet.data == "") {
      setTipoOperacao(2);
    } else if (clienteRet.data.alterarSenha) {
      setTipoOperacao(4);
      abrirModal("Informe uma nova senha");
    } else if (clienteRet.data.id > 0) {
      setTipoOperacao(3);
    }

    setCliente(clienteRet.data);
    fecharLoader();
  }

  function abrirModal(texto: String) {
    if (refModal.current) {
      setTextoModal(texto);
      refModal.current.abrirModal(texto);
    }
  }

  function renderConfirmarCodigoComponente() {
    return (
      <Box
        style={[styles.container, { alignItems: "flex-start", marginLeft: 5 }]}
      >
        <Text style={styles.corFonteSubtitulos} fontSize={"xl"}>
          Redefinição de senha
        </Text>

        <Text style={styles.textPadrao} fontSize={"md"}>
          Informe o Código Recebido no Whatsapp.
        </Text>

        <Box
          style={{
            width: wp("97%"),
            marginTop: 10,
            alignItems: "flex-end",
          }}
        >
          <Input
            icon={"call"}
            colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
            corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
            value={telefone as any}
            disabled
            onChangeText={(text: any) => {
              if (text.length > 15) {
                return;
              }
              onchange(text);
            }}
            width={"100%"}
            placeholder="Telefone"
          />

          <Input
            icon={"app-registration"}
            colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
            corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
            value={codigoRedenficao as any}
            styleBox={{ marginTop: 5 }}
            onChangeText={(text: any) => {
              setCodigoRedenficao(text);
            }}
            type="number"
            width={"100%"}
            placeholder="Código"
          />

          <Button
            style={[
              styles.button,
              {
                backgroundColor: primaryColor ?? "defaultColor",
                marginTop: 20,
              },
            ]}
            isDisabled={codigoRedenficao.length != 4}
            onPress={async () => {
              validarCodigo();
            }}
          >
            <Text style={styles.buttonText}>Validar</Text>
          </Button>
        </Box>
      </Box>
    );
  }

  function renderEsqueceuSenhaComponente() {
    return (
      <Box
        style={[styles.container, { alignItems: "flex-start", marginLeft: 5 }]}
      >
        <Text style={styles.corFonteSubtitulos} fontSize={"xl"}>
          Redefinição de senha
        </Text>

        <Text fontSize={"md"} style={styles.textPadrao}>
          Não se preocupe, vamos te ajudar a recuperar sua senha. É simples e
          rápido.
        </Text>

        <Box
          style={{
            width: wp("97%"),
            marginTop: 20,
            alignItems: "flex-end",
          }}
        >
          <Input
            icon={"call"}
            colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
            corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
            value={telefone as any}
            onChangeText={(text: any) => {
              if (text.length > 15) {
                return;
              }
              onchange(text);
            }}
            width={"100%"}
            placeholder="Telefone"
          />

          <Button
            style={[
              styles.button,
              {
                backgroundColor: primaryColor ?? "defaultColor",
                marginTop: 20,
              },
            ]}
            isDisabled={telefone.length < 15}
            onPress={async () => {
              enviarCodigo();
            }}
          >
            <Text style={styles.buttonText}>Enviar</Text>
          </Button>
        </Box>
      </Box>
    );
  }

  function confirmarNacionalidadeNumero() {
    confirmationRef.current.fecharModal();
    setNumeroInternacional(!numeroInternacional);

    if (!numeroInternacional) {
      abrirModal("Informe apenas os números do seu telefone");
    }

    setTelefone("");
  }

  function renderPrincipal() {
    return (
      <Box style={[styles.container, { backgroundColor: backgroundColor }]}>
        <Text style={[styles.headerText]}>Faça seu Login</Text>

        <Box
          style={[
            styles.imageContainer,
            {
              borderColor: primaryColor ?? "defaultColor",
              backgroundColor: "rgba(204, 204, 204, 0.1)",
            },
          ]}
        >
          <Image
            alt="Login"
            source={{
              uri: pessoaDesconhecida,
            }}
            style={styles.image}
          />
        </Box>

        <Box
          style={{
            width: wp("80%"),
            marginTop: 10,
            alignItems: "flex-end",
          }}
        >
          {getConfigEmpresa.removerValidacaoTelefone && (
            <TouchableOpacity
              onPress={() => {
                confirmationRef.current.abrirModal();
              }}
            >
              <Text fontSize={"md"} style={styles.textFoneInternacional}>
                Número {numeroInternacional ? "Nacional" : "Internacional"} ?
              </Text>
            </TouchableOpacity>
          )}

          <Input
            icon={"call"}
            colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
            corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
            value={telefone as any}
            onChangeText={(text: any) => {
              if (text.length > 15) {
                return;
              }

              onchange(text);
            }}
            width={"100%"}
            placeholder="Telefone"
          />
          {!cliente.id && (tipoOperacao == 2 || tipoOperacao == 7) && (
            <Input
              value={nome as any}
              colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
              corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
              styleBox={{ marginTop: 5 }}
              icon={"call"}
              onChangeText={(text: any) => {
                setNome(text);
              }}
              width={"100%"}
              placeholder="Nome Completo"
            />
          )}

          {!cliente.id && tipoOperacao == 2 && (
            <Input
              value={dataNascimento as any}
              colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
              corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
              styleBox={{ marginTop: 5 }}
              icon={"calendar-today"}
              type="number"
              onChangeText={(text) => {
                if (text.length > 10) {
                  return;
                }
                onchangeDtNascimento(text);
              }}
              width={"100%"}
              placeholder="EX: DD/MM/AAAA"
            />
          )}

          {((!cliente.id && tipoOperacao == 2) ||
            tipoOperacao == 3 ||
            tipoOperacao == 4) && (
            <Input
              value={senha as any}
              colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
              corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
              styleBox={{ marginTop: 5 }}
              icon={"vpn-key"}
              type={"password"}
              // style={{marginTop:10}}
              onChangeText={(text: any) => {
                setSenha(text);
              }}
              width={"100%"}
              placeholder="Senha"
            />
          )}
          {((!cliente.id && tipoOperacao == 2) || tipoOperacao == 4) && (
            <Input
              value={confirmaSenha as any}
              styleBox={{ marginTop: 5 }}
              icon={"vpn-key"}
              colorItem={getConfigEmpresa.coresPWA.corFonteFiltro}
              corBordaFiltro={getConfigEmpresa.coresPWA.corBordaFiltro}
              type={"password"}
              // style={{marginTop:10}}
              onChangeText={(text: any) => {
                setConfirmaSenha(text);
              }}
              width={"100%"}
              placeholder="Confirma Senha"
            />
          )}

          <Button
            style={[
              styles.button,
              { backgroundColor: primaryColor ?? "defaultColor" },
            ]}
            onPress={async () => {
              entrar();
            }}
          >
            <Text style={styles.buttonText}>Entrar</Text>
          </Button>

          {!getConfigEmpresa.naoSolicitarDadosCompletosPWA && (
            <Pressable
              onPress={() => {
                setTipoOperacaoRedSenha(1);
              }}
            >
              <Text style={styles.textCadastro} fontSize={"lg"}>
                Esqueceu a senha?
              </Text>
            </Pressable>
          )}
        </Box>
      </Box>
    );
  }

  //melhorar o layout e colocar loader.

  return (
    <Box
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      {tipoOperacaoRedSenha == 0
        ? renderPrincipal()
        : tipoOperacaoRedSenha == 1
        ? renderEsqueceuSenhaComponente()
        : renderConfirmarCodigoComponente()}
      <Box style={styles.footer}></Box>

      {loader && <Loader ref={refLoader} />}

      <ModalConfirmacao
        titulo={"Atenção"}
        ref={refModal}
        mensagem={textoModal as any}
        textoBotaoPositivo={"OK"}
        onConfirmPositivo={() => {
          if (refModal.current) {
            refModal.current.fecharModal();
          }
        }}
      />

      {/* ficou invertido as confições pq vai servir tanto para um quanto para outro */}
      <ModalConfirmacao
        ref={confirmationRef}
        titulo={"Atenção"}
        mensagem={`O seu número de telefone é ${
          numeroInternacional ? "Nacional" : "Internacional"
        } ?`}
        textoBotaoNegativo={"Não"}
        textoBotaoPositivo={"Sim"}
        onConfirmNegativo={() => confirmationRef.current.fecharModal()}
        onConfirmPositivo={() => confirmarNacionalidadeNumero()}
      />
    </Box>
  );
};

export default Login;
