import { StyleSheet } from 'react-native';
import { RFPercentage } from 'react-native-responsive-fontsize';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';



const createStyles = (coresPWA) =>
    StyleSheet.create({

        mainContainer: {
            alignItems: 'center',
            marginTop: 1,
            width: '100%',
            justifyContent: 'center',
        },

        pressableItem: {
            width: '100%',
            borderColor: coresPWA.corBordaItens || '#000',
            borderWidth: 1,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 5,
            marginTop: 10,
            padding: 10,
        },
        containerFlex: {
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            justifyContent: 'space-between',
            padding: 2,
        },

        styleText: {
            color: coresPWA.corFontePrimaria || '#000',
        },
        rightFlex: {
            direction: 'row',
            width: '25%',
            height: '100%',
            justifyContent: 'center',
        },
        leftFlex: {
            flexDirection: 'row',
            width: '75%',
            height: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        boxCentroPressable: {
            width: '100%',
            marginTop: 3,
            alignItems: "center"
        },
        fontTexto: {
            fontWeight: "bold",
            color: coresPWA.corFontePrimaria || '#000',
        }


    });

export default createStyles;