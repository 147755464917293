
import React, { useEffect, useRef, useState } from "react"; 
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { AspectRatio, Box, Flex, Image, Pressable, Skeleton } from "native-base";
import { RFPercentage } from "react-native-responsive-fontsize"; 
import styles from "./styles";

export default function SkeletonItens({ }) {


    return (
        <Flex style={styles.skeletonContainerFlex}>
            <Skeleton
                height={90}
                width={'25%'}
                borderRadius={5}
            />

            <Box width={'75%'} marginLeft={2}>
                <Skeleton.Text
                    width={'90%'} 
                    // borderRadius={5}
                />
            </Box>
        </Flex>
    );


}