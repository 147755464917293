
import React from "react";
import {TouchableOpacity, Linking } from "react-native";
import {  Box, Flex, Text, useColorMode } from "native-base";
import { RFPercentage } from "react-native-responsive-fontsize";
import { Feather } from '@expo/vector-icons';
import styles from "./styles";
import { useTheme } from '../../../ThemeProvider';

interface FooterProps {
    isMobile: boolean; 
    url_instagram: string;
    url_facebook: string;
    versao: string;
}

export default function Footer({ isMobile, url_instagram, url_facebook, versao } : FooterProps) {


    const { primaryColor } = useTheme();
    const { colorMode } = useColorMode();
    


    const handleOpenURL = (url: string) => {
        Linking.canOpenURL(url).then(supported => {
            if (supported) {
                Linking.openURL(url);
            } else {
                console.log("Não foi possível abrir a URL: " + url);
            }
        });
    };

    return (
        <Box style={[styles.footerContainer, { backgroundColor: colorMode == 'dark' ?  '#000' : primaryColor ?? 'defaultColor' }]}>
            <Text style={[styles.joinUsText]} fontSize="2xl" >
                Junte-se a nós !!!
            </Text>

            <Text style={[styles.connectText]} fontSize="sm">
                Conecte-se em nossas redes sociais
            </Text>

            <Flex style={styles.socialIconsContainer}>

                <TouchableOpacity onPress={() => handleOpenURL(url_facebook)}>
                    <Feather name="facebook" size={isMobile ? RFPercentage(5) : RFPercentage(3)} color={'#fff'} />
                </TouchableOpacity>

                <TouchableOpacity onPress={() => handleOpenURL(url_instagram)}>
                    <Feather name="instagram" size={isMobile ? RFPercentage(5) : RFPercentage(3)} style={styles.socialIcon} color={'#fff'} />
                </TouchableOpacity>

            </Flex>

            <Text style={[styles.connectText, { marginTop: 10 }]} fontSize="sm" >
                Versão: {versao}
            </Text>
        </Box>

    );


}